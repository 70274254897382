import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 

import logoImage from '../Assets/Images/sevakilogo2.svg';

const LogoImage = styled('img')({
  width: '80px',
  height: '50px',
});

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/'); 
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: theme.palette.primary.contrastText, boxShadow: 'none' }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="logo" sx={{ mr: 2 }} disableRipple disableFocusRipple onClick={handleLogoClick}  >
          <LogoImage src={logoImage}  alt="Sevaki Logo" sx={{ width: '160px', height: '50px', marginLeft: '85px' }} />
        </IconButton>

        <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ flexGrow: 1, display: isMobile ? 'block' : 'flex' }}>  Sevaki </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
