import React, { useState } from "react";
import { Box, Grid, Typography, Container, TextField, Button, MenuItem, Divider, } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ArrowLeft, Phone } from "lucide-react";
import useResponsive from "../hooks/useResponsive";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BottomNavBar from "../components/BottomNavbar";

const ContactUs = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    reason: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    reason: false,
    message: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: false }));
  };

  const validateForm = () => {
    const newErrors = {
      name: !formData.name,
      email: !formData.email,
      reason: !formData.reason,
      message: !formData.message,
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFormData({ name: "", email: "", reason: "", message: "" });
    }
  };
  
  const navigate = useNavigate()
  const handleclickBack = () => {
    navigate(-1)
  }

  return (
    <>
      <Navbar />
      <BottomNavBar />

      <Container>
        <Box sx={{ mt: 11 }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 3, maxWidth: "650px", }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer", }} onClick={handleclickBack}>
              <ArrowLeft sx={{ fontSize: "small" }} size={15} />
              <Typography variant="h6">Back</Typography>
            </Box>
            <Typography variant="h4" sx={{ fontWeight: "bold" }} align="center">Contact us</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", mb: 2, }}>
            <Box sx={{ display: "", alignItems: "center", justifyContent: "center", }}>
              <Typography variant="body1" align="center" sx={{ width: isSmallScreen ? "100%" : "600px", color: "footer.main", }}>
                We’re here to assist you with any questions, concerns, or feedback you may have. Whether you’re looking for support,
                partnership opportunities, or just want to learn more about our services, feel free to reach out.
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={4} mt="30px">
            {/* Contact Details Section */}
            <Grid item xs={12} md={8}>
              <Box sx={{ padding: "30px", borderRadius: "10px", border: "1px solid #E4E4E4", }}>
                <Grid item xs={12} md={9}>
                  <Typography variant="h4" gutterBottomsx={{ color: "primary.main", fontWeight: "bold", marginbottom: "10px" }}>Get in touch with us</Typography>
                  <Typography variant="body2" sx={{ color: "footer.main", marginTop: '10px', lineHeight: 'initial', }}>
                    Our team is dedicated to providing prompt and helpful assistance to meet your needs.
                    From any inquiries to technical support, we’re always happy to help.
                  </Typography>
                  <Box display="flex" alignItems="center" gap="10px" mt={3}>
                    <Phone fill="#7E60BF" size={25} color="primary.main" />
                    <Typography variant="body1" sx={{ color: "#000000" }}>
                      <a
                        href="tel:+918142447135"
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        +91 8142447135
                      </a>
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap="10px" mt={3}>
                    <Phone fill="#7E60BF" size={25} color="primary.main" />
                    <Typography variant="body1">
                      <a
                        href="tel:+918142447136"
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        +91 8142447136
                      </a>
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", color: "#E4E4E4", mt: 3, mb: 3, }}>
                    <Divider sx={{ flexGrow: 1, border: "0.2px solid #E4E4E4", }} />
                    <Box
                      sx={{
                        width: 6,
                        height: 6,
                        borderRadius: "50%",
                        backgroundColor: "#E4E4E4",
                      }}
                    />
                  </Box>
                  <Box display="flex" alignItems="center" mt={3}>
                    <EmailIcon sx={{ marginRight: "10px", color: "primary.main" }} />
                    <Typography variant="body1">
                      <a
                        href="mailto:sevaki@helpline.com"
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        help.sevaki@gmail.com
                      </a>
                    </Typography>
                  </Box>

                  <Box sx={{
                    display: "flex", alignItems: "center",
                    color: "#E4E4E4", mt: 3, mb: 3,
                  }}
                  >
                    <Divider sx={{ flexGrow: 1, border: "0.2px solid #E4E4E4", }} />
                    <Box sx={{ width: 6, height: 6, borderRadius: "50%", backgroundColor: "#E4E4E4", }} />
                  </Box>
                  <Box display="flex" alignItems="center" mt={3}>
                    <LocationOnIcon sx={{ marginRight: "10px", color: "primary.main" }} />
                    <Typography variant="body1">
                      Capital Pk Rd, Ayyappa Society, Silicon Valley, Madhapur,
                      Hyderabad, Telangana 500081
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            </Grid>

            {/* Message Form Section */}
            <Grid item xs={12} md={4}>
              <Box sx={{ padding: "20px", border: "1px solid #E4E4E4", borderRadius: "10px", }} component="form" onSubmit={handleSubmit}>
                <Typography variant="h5" gutterBottom sx={{ color: "primary.main", fontWeight: "bold" }}>
                  {" "}
                  Send a Message
                </Typography>
                <TextField fullWidth size="small" label="Name" name="name" variant="outlined"
                  onChange={handleChange}
                  // color=" #E4E4E4"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { border: "1px solid #E4E4E4", },
                      ...(errors.name && {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "error.main",
                        },
                      }),
                    }, mb: 2,
                  }}
                />
                <TextField size="small" fullWidth
                  label="Email" name="email" variant="outlined"
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { border: "1px solid #E4E4E4", },
                      ...(errors.email && {
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "error.main", },
                      }),
                    }, mb: 2,
                  }}
                />
                <TextField fullWidth size="small" select
                  label="Select Reason" name="reason" variant="outlined"
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #E4E4E4",
                      },
                      ...(errors.reason && {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "error.main",
                        },
                      }),
                    },
                    mb: 2,
                  }}
                >
                  <MenuItem value="General Inquiry">General Inquiry</MenuItem>
                  <MenuItem value="Support">Support</MenuItem>
                  <MenuItem value="Feedback">Feedback</MenuItem>
                </TextField>
                <TextField
                  size="small"
                  fullWidth
                  label="Write something..."
                  name="message"
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #E4E4E4",
                      },
                      ...(errors.message && {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "error.main",
                        },
                      }),
                    },
                    mb: 2,
                  }}
                  multiline
                  rows={4}
                />
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px", }} >
                  <Button type="submit" variant="contained" sx={{ padding: "5px 55px", borderRadius: "30px" }} >Send</Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", mt: 3, mb: 3 }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.2715435380214!2d78.38362667421097!3d17.446712601095243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91d8752f0e23%3A0x321b6b791506bcb0!2sSevaki%20technologies%20pvt.%20ltd.!5e0!3m2!1sen!2sin!4v1732519078272!5m2!1sen!2sin"
            width="100%" height="300" style={{ borderRadius: "10px", border: "1px solid  #E4E4E4" }}
            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default ContactUs;
