import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Modal, Divider, Button, Grid, LinearProgress, Rating } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Minus, Plus, ShieldCheck } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination } from 'swiper/modules';
import chef from "../../Assets/Images/Chef.png";
import dosa from '../../Assets/Images/daosa.png';
import wada from '../../Assets/Images/wada.png';
import idlly from '../../Assets/Images/idlly.png';
import Bater from '../../Assets/Images/Besanatta.png';
import aadhar from '../../Assets/Images/Aadhar.png';
import pan from '../../Assets/Images/Pan.png';
import police from '../../Assets/Images/Police.png';
import Localaddress from '../../Assets/Images/Localaddress.png';
import video from '../../Assets/Images/video.png';
import useResponsive from '../../hooks/useResponsive';
import axios from 'axios';
import apiUrl from '../../config';

const products = [
    {
        id: 1,
        name: 'Dosa Batter',
        price: 89,
        image: Bater,
    },
    {
        id: 2,
        name: 'Cleaning',
        price: 899,
        image: Bater,
    },
    {
        id: 3,
        name: 'Dosa Batter',
        price: 89,
        image: Bater,
    },
];

const verificationSteps = [
    { label: "Aadhar card verification", icon: aadhar },
    { label: "Pan card verification", icon: pan },
    { label: "Police verification", icon: police },
    { label: "Video verification", icon: video },
    { label: "Local Address verification", icon: Localaddress },
];

const maidData = [
    {
        id: 1,
        name: "Rohit Reddy",
        rating: 4.5,
        profilePic: chef,
        verified: true,
        description: "Maid cooks popular foods and custom choices (e.g. Dosa, Idly, Puri, Wada, Omelet, Tea, Coffee, Juice and more).",
        cookingTime: "1 hr 30min",
        languages: ["Telugu", "Hindi", "Tamil"],
        Note: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
];

const dishes = [
    { src: wada, alt: "Wada" },
    { src: dosa, alt: "Dosa" },
    { src: idlly, alt: "Idly" },
    { src: wada, alt: "Wada" },
    { src: wada, alt: "Wada" }
];

const ViewMoreDetails = ({ open, onClose, maid }) => {
    const [visibleCount, setVisibleCount] = useState(10);
    const [professionalReview, setProfressionalReview] = useState([])
    const { isSmallScreen } = useResponsive()
    const navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isSmallScreen ? 345 : 410,
        maxHeight: '90%',
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
    };

    const contentStyle = {
        flex: 1,
        overflowY: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        paddingRight: 1,
    };

    const sectionStyle = {
        mb: 3,
    };

    const handleReadMore = () => {
        setVisibleCount(prevCount => prevCount + 10);
    };

    const getMaidReviews = async () => {
        try {
            const response = await axios.get(apiUrl + `reviews/getuserreviewsbyuserid?userId=${maid?.id}`)
            setProfressionalReview(response.data.userAccount)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (maid && maid.id) {
            getMaidReviews();
        }
    }, []); 

    const ratingCounts = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };

    professionalReview?.forEach(review => {
        const rating = review.userReview.rating;
        if (ratingCounts[rating] !== undefined) {
            ratingCounts[rating]++;
        }
    });

    const totalReviews = professionalReview?.length;

    const ratingsDistribution = Object.keys(ratingCounts).map(rating => ({
        stars: rating,
        percentage: (ratingCounts[rating] / totalReviews) * 100
    }));

    const sortedRatings = ratingsDistribution.sort((a, b) => b.stars - a.stars);

    const calculateAverageRating = (reviews) => {
        if (!reviews || reviews.length === 0) return 0;
        const totalRating = reviews.reduce((sum, review) => sum + review.userReview.rating, 0);
        return totalRating / reviews.length;
    };

    const averageRating = calculateAverageRating(professionalReview || []);
    const roundedAverageRating = Math.round(averageRating * 2) / 2;

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px 0' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <img alt={maid?.name} src={maid?.profilePicture} style={{ width: 70, height: 70, marginRight: 12, borderRadius: "5px", objectFit: 'cover', marginBottom: "5px" }} />
                        <Box>
                            <Typography variant="h6" fontWeight="bold">{maid?.firstName}{maid?.lastName} <ShieldCheck fontSize="small" style={{ color: '#0DC07B', height: '18px', width: '16px', marginLeft: '5px', verticalAlign: 'middle' }} /> </Typography>
                            <Box display="flex" alignItems="center" mt={0.5}>
                                <Rating
                                    name="read-only"
                                    value={roundedAverageRating}
                                    precision={0.5}
                                    readOnly
                                    size="small"
                                    sx={{ mr: 0.5 }}
                                />
                                <Typography variant="body2" color="text.secondary">
                                    {roundedAverageRating.toFixed(1)} Rating
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box>  <IconButton edge="end" color="inherit" onClick={onClose} sx={{ padding: '0px', marginRight: '12px' }}> <CloseIcon /></IconButton></Box>
                </Box>
                <Box sx={contentStyle}>
                    {maidData.map((maidItem) => (
                        <Box mt={2} key={maidItem?.name}> <Typography variant="body2" gutterBottom> {maidItem?.description} </Typography>
                            <Typography variant="body2">  Cooking est time: {maidItem?.cookingTime} </Typography>
                            <Typography variant="body2" gutterBottom>  Languages: {maidItem.languages.join(', ')} </Typography>
                            <Box variant="caption" mt={1}>
                                <Typography variant="caption" component="span">Note:</Typography>
                                <Typography variant="caption" sx={{ color: '#BCBCBC', display: 'block' }}> {maidItem.Note}  </Typography>
                            </Box>
                        </Box>
                    ))}
                    <Divider sx={{ my: 2 }} />
                    <Box>
                        <Typography variant="subtitle1" gutterBottom>
                            Maid can cook
                        </Typography>
                        <Box sx={{
                            ...sectionStyle,
                            width: '100%',
                            overflow: 'scroll',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                            '-ms-overflow-style': 'none',
                            'scrollbar-width': 'none'
                        }}   >

                            <Swiper
                                modules={[Navigation, Pagination]}
                                spaceBetween={10}
                                slidesPerView={isSmallScreen ? 2.5 : 3.5}
                                navigation={false}
                                pagination={false}
                                style={{ touchAction: 'pan-y' }}
                            >
                                {dishes.map((dish, index) => (
                                    <SwiperSlide key={index}>
                                        <Box sx={{ textAlign: 'center' }}>
                                            <img
                                                src={dish.src}
                                                alt={dish.alt}
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    borderRadius: '5px',
                                                }}
                                            />
                                            <Typography variant="caption" display="block" mt={0.5}>
                                                {dish.alt}
                                            </Typography>
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Box>
                    </Box>
                    <Divider sx={{ border: '2px solid #F4F4F4', my: 1 }} />
                    <Box>
                        <Typography variant='h5' gutterBottom> Add On's </Typography>
                        <Box sx={{
                            width: '100%',
                            overflow: 'scroll',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                            '-ms-overflow-style': 'none',
                            'scrollbar-width': 'none'
                        }} >
                            <Swiper
                                modules={[Navigation, Pagination]}
                                spaceBetween={10}
                                slidesPerView={1.5}
                                navigation={false}
                                pagination={false}
                            >
                                {products.map((product) => (
                                    <SwiperSlide key={product.id}>
                                        <Box sx={{ p: 1, border: "1px solid #EBEBEB", borderRadius: "5px" }}>
                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <Box sx={{ display: 'flex', justifyContent: "space-between", gap: "10px" }}>
                                                    <img height="45" src={product.image} alt={product.name}
                                                    />
                                                    <Box>
                                                        <Typography variant="h6" component="div"> {product.name} </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            ₹{product.price}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            border: "1px solid #7E60BF",
                                                            padding: "2px 6px",
                                                            justifyContent: "space-between",
                                                            borderRadius: "5px",
                                                            gap: "10px",
                                                            backgroundColor: "#f0ebfb",
                                                            color: "#7E60BF",
                                                        }}
                                                    >
                                                        <Minus size={15} />
                                                        <Typography variant="body2" color="#7E60BF">  1  </Typography>
                                                        <Plus size={15} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Box>
                    </Box>
                    <Divider sx={{ border: '2px solid #F4F4F4', my: 1 }} />
                    <Box>
                        <Typography variant="h6" gutterBottom>  We Verified </Typography>
                        <Box sx={{
                            ...sectionStyle,
                            width: '100%',
                            overflow: 'scroll',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                            '-ms-overflow-style': 'none',
                            'scrollbar-width': 'none'
                        }} >
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={isSmallScreen ? 2.5 : 3.5}
                                pagination={{ clickable: true }}
                                navigation={false}
                            >
                                {verificationSteps.map((step, index) => (
                                    <SwiperSlide key={index}>
                                        <Box sx={{ textAlign: 'center', width: "80px" }}>
                                            <img src={step.icon} alt={step.label} style={{ width: '40px', height: '40px', marginBottom: '8px' }} />
                                            <Typography variant="body2">{step.label}</Typography>
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Box>
                    </Box>
                    <Divider sx={{ border: '2px solid #F4F4F4', my: 1 }} />
                    <Box sx={sectionStyle}>
                        <Typography variant="subtitle1" gutterBottom>
                            Reviews
                        </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Rating
                                name="read-only"
                                value={roundedAverageRating}
                                precision={0.5}
                                readOnly
                                size="small"
                                sx={{ mr: 0.5 }}
                            />
                            <Typography variant="body2" fontWeight="bold" sx={{ mr: 1 }}>
                                {roundedAverageRating}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                {professionalReview?.length} Reviews
                            </Typography>
                        </Box>
                        {
                            professionalReview?.length === 0
                                ? [5, 4, 3, 2, 1].map((rating) => (
                                    <Box key={rating} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                        <Typography variant="caption" sx={{ minWidth: 20 }}>
                                            {rating}
                                        </Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={0} // If no reviews, show 0% for all ratings
                                            sx={{
                                                flexGrow: 1,
                                                mx: 1,
                                                height: 4,
                                                width: 200,
                                                borderRadius: 2,
                                                backgroundColor: '#e0e0e0',
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'primary.main',
                                                },
                                            }}
                                        />
                                        <Typography variant="caption" color="text.secondary">0%</Typography>
                                    </Box>
                                ))
                                : sortedRatings?.map((rating, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                        <Typography variant="caption" sx={{ minWidth: 20 }}>
                                            {rating.stars}
                                        </Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={rating.percentage}
                                            sx={{
                                                flexGrow: 1, mx: 1,height: 4, width: 200,
                                                borderRadius: 2,
                                                backgroundColor: '#e0e0e0',
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'primary.main',
                                                },
                                            }}
                                        />
                                        <Typography variant="caption" color="text.secondary">
                                            {rating?.percentage.toFixed(2)}%
                                        </Typography>
                                    </Box>
                                ))
                        }
                        {professionalReview?.slice(0, visibleCount)?.map((review, index) => (
                            <Box key={index} sx={{ mb: 2 }}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs>
                                        <Typography variant="caption" fontWeight="bold">
                                            {review.userName}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary" display="block">

                                            {new Date(review.userReview.createdDate.split('.')[0]).toLocaleDateString('en-US', {
                                                weekday: 'short',
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography variant="body2" sx={{ mt: 0.5 }}>
                                    {review.userReview.description}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <Rating
                                        name="read-only"
                                        value={review.userReview.rating}
                                        precision={0.5}
                                        readOnly
                                        size="small"
                                        sx={{ mr: 0.5 }}
                                    />
                                    <Typography variant="body2" fontWeight="bold" sx={{ mr: 1 }}>
                                        {review.userReview.rating}
                                    </Typography>
                                </Box>

                                {index < professionalReview.length - 1 && <Divider sx={{ mt: 1 }} />}
                            </Box>
                        ))}

                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                            {professionalReview?.length > 10 && (
                                <Typography sx={{ color: 'primary.main', cursor: 'pointer' }} variant="caption" onClick={handleReadMore}>
                                    Read More
                                </Typography>
                            )}
                            {professionalReview?.length === 0 && (
                                <Typography sx={{ color: 'text.secondary' }} variant="body2">
                                    No reviews available for this maid.
                                </Typography>
                            )}
                        </Box>

                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained" 
                    sx={{ backgroundColor: 'primary.main', borderRadius: '20px', width: '100%', maxWidth: 200 }} 
                    onClick={() => navigate('/checkout')}>  Add to Cart </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ViewMoreDetails;