import React, { useState } from 'react';
import { Box, Typography, Modal, TextField, Button, MenuItem, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    padding: '40px',
};

const MobileNumber = () => {
    const [open, setOpen] = useState(true);
    const [mobileNumber, setMobileNumber] = useState('');
    const [countryCode, setCountryCode] = useState('+91');
    const [isWhatsAppChecked, setIsWhatsAppChecked] = useState(false);

    const handleMobileChange = (e) => {
        const { value } = e.target;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setMobileNumber(value);
        }
    };

    const handleSubmit = () => {
        if (mobileNumber.length === 10 && isWhatsAppChecked) {
            console.log("Form Submitted");
            console.log("Mobile Number:", `${countryCode} ${mobileNumber}`);
            console.log("WhatsApp Updates:", isWhatsAppChecked ? "Enabled" : "Disabled");
        }
    };

    const handleClose = () => setOpen(false);

    const isButtonEnabled = mobileNumber.length === 10 && isWhatsAppChecked;

    return (
        <Box>
            <Typography variant="h5" sx={{ marginBottom: '20px' }}>Notifications</Typography>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography id="modal-title" variant="h5" component="h2">
                            Update mobile number
                        </Typography>
                        <IconButton sx={{ position: 'relative', bottom: 40, left: 35 }} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <TextField
                            select
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            size="small"
                            sx={{
                                width: '100px',
                                color: 'black', // For text
                                '.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black', // Border color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#E4E4E4', // Hover border color
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#E4E4E4', // Focus border color
                                    },
                                },
                            }}
                            InputProps={{
                                style: { color: 'black' }, 
                            }}
                        >
                            <MenuItem value="+91">
                                +91
                            </MenuItem>
                            {/* <MenuItem  value="+1">
        +1
    </MenuItem>
    <MenuItem  value="+44">
        +44
    </MenuItem> */}
                        </TextField>



                        <TextField
                            label="Mobile Number"
                            value={mobileNumber}
                            size="small"
                            type="tel"
                            onChange={handleMobileChange}
                            sx={{
                                width: '100%',
                            }}
                            inputProps={{
                                maxLength: 10,
                            }}
                        />
                    </Box>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isWhatsAppChecked}
                                onChange={(e) => setIsWhatsAppChecked(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Get order updates on WhatsApp"
                        sx={{ marginBottom: '20px' }}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{ width: '150px', borderRadius: '20px' }}
                            disabled={!isButtonEnabled} 
                        >
                            Get OTP
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default MobileNumber;
