import React, { useContext, useState } from 'react';
import { Box, Modal, Typography } from "@mui/material";
import { X } from "lucide-react";
import { Context } from '../../context/Context';
import useResponsive from '../../hooks/useResponsive';
import apiUrl from '../../config';

const ServicePopup = () => {
    const { handleCloseServicePopup, openServicePopUp, services, handleOpenCategoryPopUp } = useContext(Context);
    const { isSmallScreen, isMediumScreen } = useResponsive();
    
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isSmallScreen ? "380px" : isMediumScreen ? "400px" : "600px",
        height: "400px", // Fixed height for the modal
        bgcolor: "background.paper",
        boxShadow: 24,
        border: "none",
        outline: "none",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
    };

    const modalContentStyle = {
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    }

    const servicesContainerStyle = {
        display: "flex",
        flexWrap: "wrap",
        gap: "15px",
        width: isSmallScreen ? "280px" : isMediumScreen ? "400px" : "500px",
        maxHeight: "300px",
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            display: "none",
        },
    };

    const handleServiceClick = async (service) => {
        const getMaidDetails = JSON.parse(localStorage.getItem("maidDetails")) || {};
        const updatedMaidDetails = { ...getMaidDetails, serviceId: service.id };
        localStorage.setItem('maidDetails', JSON.stringify(updatedMaidDetails));
        handleOpenCategoryPopUp();
    };

    return (
        <Box>
            <Modal open={openServicePopUp} onClose={handleCloseServicePopup}>
                <Box sx={modalStyle}>
                    <Box sx={modalContentStyle}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 4 }}>
                            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                What are you looking for?
                            </Typography>
                            <X cursor="pointer" onClick={handleCloseServicePopup} />
                        </Box>

                        <Box sx={servicesContainerStyle}>
                            {services.filter((val) => val.isDeleted !== true).map((item) => {
                                const isActive = item.isActive === false;
                                return (
                                    <Box
                                        key={item.id}
                                        sx={{ display: "flex", flexDirection: "column", textAlign: "center", gap: "2px" }}
                                        onClick={() => !isActive && handleServiceClick(item)}
                                    >
                                        <Box
                                            sx={{
                                                border: "1px solid #BCBCBC",
                                                padding: isSmallScreen ? "7px 25px" : isMediumScreen ? "10px 50px" : "7px 40px",
                                                borderRadius: "10px",
                                                cursor: isActive ? "not-allowed" : "pointer",
                                                opacity: isActive ? 0.5 : 1,
                                                filter: isActive ? "grayscale(100%)" : "none"
                                            }}
                                        >
                                            <img
                                                src={apiUrl+`${item.imagePath}`}
                                                width="30px"
                                                height="30px"
                                                alt="not found"
                                                style={{
                                                    filter: isActive ? "grayscale(100%)" : "none" // ensures the image is grayscale when deleted
                                                }}
                                            />
                                        </Box>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: isActive ? "#B0B0B0" : "#000000", // Apply a gray color when deleted
                                                filter: isActive ? "grayscale(100%)" : "none" // Apply grayscale filter to the text as well
                                            }}
                                        >
                                            {item.name}
                                        </Typography>
                                    </Box>

                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ServicePopup;
