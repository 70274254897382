import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Typography, IconButton, List, ListItem, ListItemText, Paper, AppBar, Toolbar, Divider, InputAdornment, Container } from '@mui/material';
import { X, SendHorizontal } from 'lucide-react';

const Chat = () => {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([
        { id: 1, sender: 'bot', text: "Hey Rohit! I am Vincy, Sevaki's own help assistant. Thank you for reaching out to me.", time: '10:40 AM' },
        { id: 2, sender: 'bot', text: 'Please select the applicable option. Below the options:', time: '10:40 AM' },
        { id: 3, sender: 'bot', text: '1. Change Mobile Number\n2. Change Address\n3. Notifications\n4. Change Email Id\n5. Chat with Agent', time: '10:40 AM' },
    ]);

    const options = [
        { id: 1, text: 'Change Mobile Number' },
        { id: 2, text: 'Change Address' },
        { id: 3, text: 'Notifications' },
        { id: 4, text: 'Change Email Id' },
        { id: 5, text: 'Chat with Agent' },
    ];

    const [userInput, setUserInput] = useState('');

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleSendMessage = () => {
        if (userInput.trim()) {
            const newMessage = { id: messages.length + 1, sender: 'user', text: userInput, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) };
            setMessages([...messages, newMessage]);

            const selectedOption = options.find(opt => opt.id.toString() === userInput.trim());

            if (selectedOption) {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { id: prevMessages.length + 1, sender: 'bot', text: `You selected: ${selectedOption.text}`, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }
                ]);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { id: prevMessages.length + 1, sender: 'bot', text: 'Invalid option, please select a valid number.', time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }
                ]);
            }

            setUserInput('');
        }
    };

    const messagesEndRef = useRef(null);

    // Scroll to bottom whenever messages change
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <>

            <Box display="flex" flexDirection="column" height="100vh" width="100%" sx={{ bgcolor: '#fff' }}>
                {/* Header */}
                <Container>
                    <AppBar position="static" color="transparent" elevation={0} sx={{ borderBottom: 'none' }}>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h6" sx={{ flexGrow: 1,color: '#002d6e', fontWeight: 'bold' }}> Sevaki <span style={{ fontWeight: 'normal' }}>Support</span> </Typography>
                            <Box
                               sx={{display:"flex",alignItems:'center', cursor:"pointer"}}
                                onClick={() => {
                                    // Navigate to the FAQ page when 'Close Chat' is clicked
                                    navigate('/faq');
                                }}

                                // sx={{ color: '#7E60BF' }}
                            >
                                <X size={15} />
                                <Typography variant="body2" sx={{ marginLeft: '4px', color: '#7E60BF' }}> Close Chat  </Typography>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Container>

                {/* HR line */}
                <hr style={{ border: '1px solid #e0e0e0', margin: 0 }} />

                <Container>
                    <Box
                        flexGrow={1}

                        p={2}
                        overflow="auto"  
                        component={Paper}
                        sx={{
                            backgroundColor: '#fff',
                            borderRadius: '0',
                            boxShadow: 'none',


                            "&::-webkit-scrollbar": {
                                display: "none", 
                            },
                            "-ms-overflow-style": "none",  
                            "scrollbar-width": "none",  
                        }}
                    >
                        <Divider sx={{ my: 1, border: '1px solid #ccc', borderRadius: '20px', width: 'fit-content', mx: 'auto', px: 2 }}>
                            <Typography variant="body2" color="textSecondary">Today</Typography>
                        </Divider>

                        <List>
                            {messages.map((message) => (
                                <ListItem
                                    key={message.id}
                                    sx={{
                                        justifyContent: message.sender === 'bot' ? 'flex-start' : 'flex-end'
                                    }}
                                >
                                    <Paper sx={{ padding: '10px', borderRadius: '10px', border: '1px solid #ccc',
                                            backgroundColor: message.sender === 'bot' ? '#F7F7F7' : '#F7F7F7',
                                            maxWidth: '75%', boxShadow: 'none'
                                        }}
                                    >
                                        <ListItemText primary={
                                                <>
                                                    {message.text.split('\n').map((line, i) => (
                                                        <span key={i} style={{ textAlign: "right" }}>
                                                            {line}  <br />
                                                        </span>
                                                    ))}
                                                    <Typography variant="caption" color="textSecondary" sx={{ display: 'block', textAlign: 'right' }}> {message.time} </Typography>
                                                </>
                                            }
                                        />
                                    </Paper>
                                </ListItem>
                            ))}
                            <div ref={messagesEndRef} />
                        </List>
                    </Box>
                </Container>

                {/* Input Field */}
                <Box
                    display="flex"
                    p={0.3}
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        height: "8% !important",
                        bgcolor: 'white',
                        borderTop: '1px solid #e0e0e0' // Add border on top of input area
                    }}
                >
                    <TextField
                        fullWidth
                        value={userInput}
                        size='small'
                        onChange={handleInputChange}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none", // Remove border
                                },
                                "&:hover fieldset": {
                                    border: "none", // Ensure no border on hover
                                },
                                "&.Mui-focused fieldset": {
                                    border: "none", // Ensure no border when focused
                                },
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSendMessage}>
                                        <SendHorizontal size={22} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>

        </>
    );
};

export default Chat;
