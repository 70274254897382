import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { ArrowLeft } from 'lucide-react';
import EmojiAnimation from '../Animations/EmojiAnimation';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/Context';
import useResponsive from '../../hooks/useResponsive';
import apiUrl from '../../config';

const OtherServices = ({ handleSelectSubService }) => {
    const { subServices, getSubServicesForZoneId, loading } = useContext(Context);
    const serviceId = JSON.parse(localStorage.getItem("maidDetails"));

    const navigate = useNavigate();
    const handleclickBack = () => {
        navigate("/");
    };

    const { isSmallScreen } = useResponsive();

    useEffect(() => {
        if (subServices.length === 0) {
            getSubServicesForZoneId(serviceId?.serviceId);
        }
    }, []);

    return (
        <Box sx={{ paddingTop: '16px', mt: isSmallScreen ? 0 : 10 }}>
            <IconButton edge="start" color="inherit" aria-label="back" style={{ paddingTop: "20px" }} onClick={handleclickBack} disableRipple
                disableFocusRipple
            >
                <ArrowLeft height={15} width={15} />
                <Typography variant="body1" component="span" ml={1} fontSize={14} > Back </Typography>
            </IconButton>

            <Typography variant="h4" lineHeight={1.2} fontWeight="bold" my={1}> Transform your space with cleaning hacks </Typography>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'flex-start', border: isSmallScreen ? "none" : '1px solid grey', borderRadius: '8px', padding: 2, width: '80%', maxWidth: '400px', marginTop: "25px" }} >
                    <Typography variant="h6" fontWeight="medium" mb={2} sx={{ alignSelf: 'flex-start', paddingLeft: '20px', fontWeight: 'bold', fontSize: '18px' }} > Other Services </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: isSmallScreen ? 'nowrap' : 'wrap',
                            justifyContent: 'flex-start',
                            overflowX: "auto",
                            width: '100%',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        {subServices.map((service, index) => (
                            <Box
                                key={index}
                                sx={{
                                    textAlign: 'center',
                                    margin: isSmallScreen ? "10px" : 0.5,
                                    width: isSmallScreen ? 'auto' : 'calc(33.33% - 0.7rem)', 
                                    marginBottom: '18px', // Vertical space between items
                                    cursor: "pointer"
                                }}
                                onClick={() => handleSelectSubService(service)}
                            >
                                <img
                                    src={apiUrl + `${service.imagePath}`}
                                    alt={service.name}
                                    style={{ width: '60px', height: '45px', borderRadius: '8px' }}
                                />
                                <Typography variant="body2" lineHeight={1.2}>
                                    {service.name}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}

            <Box sx={{
                position: 'sticky',
                bottom: 0,
                display: isSmallScreen ? 'none' : 'flex',
                alignItems: 'center',
                mt: 1,
                width: '100%',
                backgroundColor: 'white', // Optional: to give it a background
                padding: '10px 0', // Optional: add some padding for spacing
                zIndex: 1000 // Ensure it stays on top of other content if needed
            }}>
                <Typography
                    variant="h4"
                    lineHeight={1}
                    fontWeight="bold"
                    sx={{
                        color: 'rgba(0, 0, 0, 0.1)',
                        width: "80%",
                        marginTop: "20px"
                    }}
                >
                    We are transparent
                    <span style={{ display: 'flex', gap: '7px', fontFamily: "Genhead" }}>with you. Sevaki  <EmojiAnimation /></span>
                </Typography>
            </Box>


        </Box>
    );
};

export default OtherServices;
