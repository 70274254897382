// import React, { useState, useEffect } from 'react';
// import { Box, LinearProgress } from '@mui/material';
// import sevakilogo from '../Assets/Images/Logo.svg';

// const Loader = () => {
//     const [progress, setProgress] = useState(0);
//     const [isFinished, setIsFinished] = useState(false);
//     const [split, setSplit] = useState(false);

//     useEffect(() => {
//         const timer = setInterval(() => {
//             setProgress((oldProgress) => {
//                 if (oldProgress >= 75 && !split) {
//                     setSplit(true);  // Move the image after the progress reaches 75
//                 }
//                 if (oldProgress === 100) {
//                     clearInterval(timer);
//                     setIsFinished(true);  // Mark the loading as complete
//                     return 100;
//                 }
//                 const diff = Math.random() * 10;
//                 return Math.min(oldProgress + diff, 100);
//             });
//         }, 100);

//         return () => {
//             clearInterval(timer);
//         };
//     }, [split]);

//     return (
//         <Box display="flex" justifyContent="center" alignItems="center" height="100vh" flexDirection="column" bgcolor="white" overflow="hidden">
//             {/* Image Box */}
//             <Box 
//                 display="flex" 
//                 alignItems="center" 
//                 marginBottom={0.5} 
//                 sx={{
//                     transition: 'transform 1s ease-out',
//                     transform: split ? 'translateY(-100vh)' : 'none',  // Apply transform only to the image
//                 }}
//             >
//                 <img src={sevakilogo} alt="Sevaki" width="200" height="100" style={{ marginRight: 10 }} />
//             </Box>

//             {/* Linear Progress Box */}
//             <Box sx={{ width: '18%', marginTop: 0.5 }}>
//                 <LinearProgress 
//                     variant="determinate" 
//                     value={progress}
//                     sx={{
//                         backgroundColor: '#E4E4E4',
//                         '& .MuiLinearProgress-bar': { backgroundColor: '#7E60BF' },
//                     }}
//                 />
//             </Box>
//         </Box>
//     );
// };

// export default Loader;



// import React, { useState, useEffect } from 'react';
// import { Box, CircularProgress } from '@mui/material';
// import sevakilogoloader from '../Assets/Images/sevakilogoloader.svg';

// const Loader = () => {
//     const [progress, setProgress] = useState(0);
//     const [split, setSplit] = useState(false);
//     const [fadeInHome, setFadeInHome] = useState(false); // State for fade-in effect
//     const [slideUpHome, setSlideUpHome] = useState(false); // State for sliding up effect
//     const [showHome, setShowHome] = useState(false); // State to control if the home page is shown

//     useEffect(() => {
//         const timer = setInterval(() => {
//             setProgress((oldProgress) => {
//                 if (oldProgress >= 0 && !split) {
//                     setSplit(true); // Move the image after the progress reaches 75
//                 }
//                 if (oldProgress === 100) {
//                     clearInterval(timer);
//                     return 100;
//                 }
//                 const diff = Math.random() * 10;
//                 return Math.min(oldProgress + diff, 100);
//             });
//         }, 100);

//         return () => {
//             clearInterval(timer);
//         };
//     }, [split]);

//     return (
//         <Box
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             height="100vh"
//             flexDirection="column"
//             bgcolor="white"
//             overflow="hidden"
//         >
//             <Box display="flex"justifyContent="center" alignItems="center" position="relative"
//                 sx={{ marginTop: 0.5, width: '150px', height: '150px' }}
//             >
//                 <CircularProgress
//                     // variant="determinate"
//                     value={progress}
//                     size={150}
//                     thickness={1}
//                     // sx={{
//                     //     color: '#7E60BF',
//                     //     backgroundColor: '#E4E4E4',
//                     // }}
//                 />
//                 <Box
//                     position="absolute"
//                     top="50%"
//                     left="50%"
//                     style={{
//                         transform: 'translate(-50%, -50%)',
//                     }}
//                 >
//                     <img src={sevakilogoloader} alt="Sevaki" width="80" height="40" />
//                 </Box>
//             </Box>

//         </Box>
//     );
// };

// export default Loader;


import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import sevakilogoloader from '../Assets/Images/sevakilogoloader.svg';

const Loader = () => {
    const [progress, setProgress] = useState(0);
    const [split, setSplit] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    clearInterval(timer);
                    return 100;
                }
                return Math.min(oldProgress + 10, 100);
            });
        }, 90); 

        return () => {
            clearInterval(timer);
        }; 
    }, []);

    return (
        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',
            height:'100vh',flexDirection:'column',bgcolor:'white',overflow:'hidden'}} >
            <Box sx={{ marginTop: 0.5, width: '150px', height: '150px',display:'flex',justifyContent:'center',alignItems:'center',position:'relative' }} >
                <CircularProgress variant="determinate" value={progress} size={150} thickness={1}
                />
                <Box position="absolute" top="50%" left="50%" style={{ transform: 'translate(-50%, -50%)', }} >
                    <img src={sevakilogoloader} alt="Sevaki" width="80" height="40" />
                </Box>
            </Box>
        </Box>
    );
};

export default Loader; 

 