import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Box, Typography, Avatar, Container } from "@mui/material";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import useResponsive from "../../hooks/useResponsive";
import "../../style/swiper.css";
import doublequotes from "../../Assets/Images/doublequotes.svg";


const testimonialsdata = [
  {
    name: "Akhil Akula",
    image: "https://via.placeholder.com/150",
    text: "Amazing service! Every dish is fresh, flavorful. Highly recommend to use Sevaki right at home !",
  },
  {
    name: " Natasha",
    image: "https://via.placeholder.com/150",
    text: "Sevaki’s cooking made mealtime stress-free. Highly customizable and delicious! Perfect for busy families and friends..",
  },
  {
    name: "Suman Reddy",
    image: "https://via.placeholder.com/150",
    text: "Sevaki provided amazing cooking assistance. Deliciousperfectly to our preferences. it is A real time-saver!",
  },
  {
    name: "Sreekar",
    image: "https://via.placeholder.com/150",
    text: "Stress-free cooking with Sevaki, fresh meals made for you ",
  },
  {
    name: "Sai Ram",
    image: "https://via.placeholder.com/150",
    text: "Perfect for busy families seeking home .Sevaki offers reliable cooking services with personalized meal options. ",
  },
  {
    name: "Vishal",
    image: "https://via.placeholder.com/150",
    text: "Perfect for busy families seeking home .Sevaki offers reliable cooking services with personalized meal options. ",
  },
];

const Testimonials = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  let swiperInstance = null;

  return (
    <>
      <Container>
        <Box sx={{ padding: isSmallScreen ? "25px 0" : "auto", marginBottom: "3rem" }} >
          <Box> <Typography variant="h3">Testimonials</Typography> </Box>
          <Box sx={{ maxWidth: "410px", marginTop: "26px", color: "#BCBCBC" }}>
            <Typography variant="body1" sx={{ color: "#BCBCBC", maxWidth: '560px' }}>
              Sevaki provides reliable maid services, including cooking
              assistance. Customers can share reviews on their cooking
              experiences, ensuring high-quality, personalized culinary support
              for every household.
            </Typography>
          </Box>

          <Box sx={{ overflow: "hidden", marginTop: isSmallScreen ? "0px" : "30px", maxHeight: "400px", padding: "0px 10px" }}>
            <Box sx={{ position: "relative", top: "18px", zIndex: 2 }}>
              <img style={{ height: "45px", paddingLeft: '7px', }} src={doublequotes} alt="doublequotes" />
            </Box>


            <Swiper
              pauseOnMouseEnter={true}
              breakpoints={{
                430: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                767: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              loop={true}
              navigation={false}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              onSwiper={(swiper) => { swiperInstance = swiper }}
              style={{ maxHeight: "inherit" }}  >
              {testimonialsdata.map((testimonial, index) => (
                <SwiperSlide className="mySwiper-s" key={index} style={{ display: "flex", alignItems: 'center' }}>

                  <Box sx={{ display: "flex", alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', border: "1px solid #eee", borderRadius: "8px", padding: isSmallScreen ? "0px" : isMediumScreen ? "7px" : "20px 15px", alignItems: "center", height: "180px", }}>
                      <Typography variant="body1" sx={{ color: "#BCBCBC", paddingLeft: '3.5rem', marginBottom: isSmallScreen ? "0px" : "12px",mt:isSmallScreen?'20px':'0' }}> {testimonial.text} </Typography>
                      <Box sx={{ display: "flex", alignItems: 'center', width: '100%' }}>
                        <Avatar src={testimonial.image} sx={{ width: 46, height: 46, }} />
                        <Typography variant="h6" sx={{ color: "#7E60BF", marginLeft: "10px" }}> {testimonial.name}</Typography>
                      </Box>
                    </Box>
                  </Box>

                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Testimonials;
