import React, { useContext, useState } from 'react';
import { Box, Button, TextField, Modal, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import apiUrl from '../../config';
import { Context } from '../../context/Context';
import useResponsive from '../../hooks/useResponsive';

const Reschedulepopup = ({ open, onClose, booking }) => {
    const { userToken } = useContext(Context)
    const [frequency, setFrequency] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('00:00');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { isSmallScreen, isMediumScreen } = useResponsive();

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    const handleConfirm = async () => {
        if (!selectedDate || !selectedTime) {
            setError('Date and Time are required!');
            return;
        }

        const formattedDateTime = `${selectedDate}T${selectedTime}:00`;

        setLoading(true);
        setError('');

        try {
            const payload = {
                // ...booking.bookingDetail,
                // frequency,
                // bookingSlot: [
                //     {
                //         ...booking.bookingDetail.bookingSlot[0],
                expectedStartDateTime: formattedDateTime,
                id: booking?.bookingDetail?.bookingSlot[0]?.id,
                // WorkerUserId: booking.workerDetails.id,
                // UserId: booking.userId
                //     }
                // ],
            };
            const response = await axios.post(apiUrl + 'booking/reschedulebooking', payload, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });

            if (response.data.status === true) {
                onClose();
            } else {
                setError('Failed to update booking. Please try again.');
            }
        } catch (err) {
            console.log('Error updating booking:', err);
            setError('An error occurred while updating booking.');
        } finally {
            setLoading(false);
        }
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? "320px" : isMediumScreen ? "480px" : "500px",
        backgroundColor: 'white',
        boxShadow: 14,
        p: 4,
        outline: 'none',
        borderRadius: '10px',
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="form-modal-title"
            aria-describedby="form-modal-description"
        >
            <Box sx={modalStyle}>
                <Box sx={{ display: "flex", justifyContent: "end" }}><CloseIcon onClick={onClose} sx={{ cursor: "pointer" }} />
                </Box>

                <h2 id="form-modal-title">Update Slot</h2>
                {error && <Box sx={{ color: 'red', mb: 2 }}>{error}</Box>}
                <Box
                    sx={{
                        display: isSmallScreen ? "block" : 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',

                    }}>

                    <TextField
                        label="Date"
                        type="date"
                        size='small'
                        sx={{ width: isSmallScreen ? "100%" : "180px", height: '40px', margin: isSmallScreen ? "10px 0px 10px 0px" : "0px" }}
                        value={selectedDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        label="Time"
                        type="time"
                        size="small"
                        value={selectedTime}
                        sx={{ width: isSmallScreen ? "100%" : "180px", height: '40px', margin: isSmallScreen ? "10px 0px 10px 0px" : "0px" }}
                        onChange={handleTimeChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 4 }}>
                    <Button onClick={onClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Confirm'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default Reschedulepopup;
