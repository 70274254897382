import React, { useContext, useEffect, useState } from "react";
import { Card, CardContent, Typography, Box, Container, Chip, CircularProgress } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import axios from "axios";
import DosaImage from "../../../Assets/Images/DosaImage.png";
import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/Context";
import apiUrl from "../../../config";
import Navbar from "../../../components/Navbar";
import BottomNavBar from "../../../components/BottomNavbar";

const statusColors = {
  Accepted: "#0DC07B",
  Scheduled: "#FF8000",
  Cancelled: "#FF0000",
  InProgress: "yellow",
  Pending: "#5BC0EB",
};

const MyBookings = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const navigate = useNavigate();
  const [bookingsData, setBookingsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userToken, userDetails } = useContext(Context);

  useEffect(() => {
    const fetchBookings = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${apiUrl}booking/searchbookings`, {
          userId: userDetails.id,
          pageSize: 10,
          pageNumber: 1,
        });
        setBookingsData(response.data.bookings);
      } catch (err) {
        setError(err.response?.data.message || "Failed to fetch bookings data");
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [userToken]);

  const handleBooking = (id) => {
    navigate(`/bookingdetails/${id}`);
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const suffix = ["th", "st", "nd", "rd"][((day % 10) > 3 || (day % 100) > 10 && (day % 100) < 14) ? 0 : day % 10];
    const dayOfWeek = date.toLocaleString("en-US", { weekday: "short" }); // "Tue"
    const month = date.toLocaleString("en-US", { month: "long" }); // "October"
    const year = date.getFullYear(); // 2024
    return `${dayOfWeek} ${day}${suffix}, ${month} ${year}`;
  };

  const formatTime = (date) => {
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  if (loading) {
    return (
      <>
        <Navbar />
        <BottomNavBar />
        <Container>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <CircularProgress />
          </Box>
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Navbar />
        <BottomNavBar />
        <Container>
          <Box sx={{ textAlign: "center", marginTop: "20px" }}>
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          </Box>
        </Container>
      </>
    );
  }


  return (
    <>
      <Navbar />
      <BottomNavBar />

      <Container>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mt: 10 }}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                maxWidth: "620px",
                justifyContent: "space-between",
                margin: "20px 0 10px 0",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                color="initial"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              >
                <ArrowLeftIcon style={{ width: "15px" }} /> Back
              </Typography>
              <Typography variant="h5" color="initial" sx={{ fontWeight: "700" }}>
                My Bookings
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" alignItems="center" width="100%" justifyContent="space-between">
              {bookingsData?.map((booking) => {
                const expectedStartDateTime = booking.booking.bookingSlot?.[0]?.expectedStartDateTime && new Date(booking.booking.bookingSlot[0].expectedStartDateTime);
                const formattedDate = expectedStartDateTime ? formatDate(expectedStartDateTime) : "N/A";
                const formattedTime = expectedStartDateTime ? formatTime(expectedStartDateTime) : "N/A";
                return (
                  <Box key={booking.booking.id} width={isSmallScreen ? "100%" : isMediumScreen ? "100%" : "49%"}>
                    <Card
                      style={{
                        margin: "10px 0",
                        display: "flex",
                        alignItems: "center",
                        boxShadow: "none",
                        border: "1px solid #E4E4E4",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleBooking(booking.booking.bookingID)}
                    >
                      <CardContent
                        style={{
                          display: "flex",
                          flexDirection: isSmallScreen ? "column" : "",
                          alignItems: isSmallScreen ? "" : "center",
                          justifyContent: "space-between",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={booking.booking.image || DosaImage}
                            alt={booking.booking.foodType || "Booking image"}
                            style={{
                              width: "80px",
                              height: "80px",
                              backgroundColor: "#f0f0f0",
                              margin: "10px",
                            }}
                          />
                          <Box sx={{ marginLeft: "15px" }}>
                            <Typography variant="h5" fontWeight={600} fontSize={22}>
                              {booking.booking.foodType}
                            </Typography>
                            <Typography color="textSecondary" sx={{ color: "black", fontSize: "14px" }}>
                              {formattedDate} | {formattedTime}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          <Chip
                            label={booking.bookingStatusName}
                            style={{
                              backgroundColor: statusColors[booking.bookingStatusName] || "#ccc", // Default color for invalid status
                              color: "white",
                              width: "110px",
                            }}
                          />
                          <ArrowRightIcon style={{ width: "20px" }} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                );
              })}


            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default MyBookings;