import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { BadgePercent } from 'lucide-react';

const Advertisements = () => {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 11 }}>
            <Box sx={{ padding: '16px 0' }}>
                <Box sx={{  position: 'relative', borderRadius: '8px',overflow: 'hidden', cursor: 'pointer', }} >
                    <video style={{ width: '100%', height: '75%', objectFit: 'cover', borderRadius: '12px', }}
                        loop muted autoPlay
                    >
                        <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                </Box>
                <Card sx={{ mt: 1, borderRadius: '7px', padding: '5px', boxShadow: 'none', border: '1px solid lightgrey', }} >
                    <Typography variant="h6" fontWeight="bold" mb={2} ml={1.5}> Offers </Typography>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <BadgePercent size={15} fill="#0DC07B" color="#FFFFFF" style={{ marginLeft: '8px', backgroundColor: "#E4E4E4", padding: "5px", borderRadius: "50%" }} />
                            <Typography fontWeight="bold" sx={{ ml: 1 }}>Use ICICI Credit Card</Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary" fontSize="10px" fontWeight="bold" sx={{ ml: 4.5 }}> Cashback up to 50% on first order </Typography>
                    </Box>
                    <hr style={{ width: "78%", marginRight: "10px", color: "#E4E4E4 !important" }} />
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <BadgePercent size={15} fill="#0DC07B" color="#FFFFFF" style={{ marginLeft: '8px', backgroundColor: "#E4E4E4", padding: "5px", borderRadius: "50%" }} />
                            <Typography fontWeight="bold" sx={{ ml: 1 }}> CRED Cashback </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary" fontSize="10px" fontWeight="bold" sx={{ ml: 4.5 }}>  Cashback up to ₹500 </Typography>
                    </Box>
                </Card>
                <Card sx={{
                    mt: 2, height: '175px',
                    borderRadius: '8px', backgroundColor: '#f0f0f0', display: 'flex',
                    justifyContent: 'center', alignItems: 'center', boxShadow: 'none',
                }}>
                    <Typography variant="body2" color="textSecondary"> AD PLACE HOLDER</Typography>
                </Card>
            </Box>
        </Box>
    );
};

export default Advertisements;