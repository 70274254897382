import React, { useContext, useEffect } from "react";
import { Box, Modal, Typography, CircularProgress } from "@mui/material";
import { X } from "lucide-react";
import { Context } from "../context/Context";
import useResponsive from "../hooks/useResponsive";
import apiUrl from "../config";

const modalStyle = (isSmallScreen, isMediumScreen) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isSmallScreen ? "380px" : isMediumScreen ? "400px" : "500px",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  outline: "none",
  borderRadius: "6px",
  maxHeight: "80vh",
  overflowY: "auto",
});

const CategoryPopUp = () => {
  const { isMediumScreen, isSmallScreen, loading } = useResponsive();
  const { subServices, openCategoryPopUp, handleOpenFilterPopUp, handleCloseCategoryPopUp, pincode, getSubServicesForZoneId } = useContext(Context);
  const serviceId = JSON.parse(localStorage.getItem("maidDetails"));

  useEffect(() => {
    if (pincode) {
      getSubServicesForZoneId(serviceId.serviceId);
    }
  }, [pincode]);

  const handleStoreServiceFields = async (service) => {
    const getMaidDetails = JSON.parse(localStorage.getItem("maidDetails")) || {};
    const updatedMaidDetails = { ...getMaidDetails, subCategoryId: service };
    localStorage.setItem('maidDetails', JSON.stringify(updatedMaidDetails));
    handleOpenFilterPopUp();
  };

  return (
    <Box>
      <Modal open={openCategoryPopUp} onClose={handleCloseCategoryPopUp}>
        <Box sx={modalStyle(isSmallScreen, isMediumScreen)}>
          <Box sx={{ borderRadius: "10px", padding: isSmallScreen ? "20px" : "40px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 4 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                What segments are you looking for?
              </Typography>
              <X cursor="pointer" onClick={handleCloseCategoryPopUp} />
            </Box>

            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "30px", justifyContent: "start" }}>
                {subServices.length > 0 ? (
                  subServices.filter((val) => val.isDeleted !== true).map((item) => {
                    const isActive = item.isActive === false;

                    return (
                      <Box key={item.id}sx={{
                          display: "flex",flexDirection: "column",
                          textAlign: "center",  gap: "10px",
                          cursor: isActive ? "not-allowed" : "pointer",
                          opacity: isActive ? 0.5 : 1,
                          filter: isActive ? "grayscale(100%)" : "none"
                        }}
                        onClick={() => !isActive && handleStoreServiceFields(item)}
                      >
                        <Box sx={{ border: "1px solid #BCBCBC", padding: "10px", borderRadius: "10px" }}>
                          <img src={apiUrl+`${item.imagePath}`} width="40px" height="40px" alt={item.name || "Category Icon"} />
                        </Box>
                        <Typography variant="h6" sx={{ color: "#000000" }}> {item.name} </Typography>
                      </Box>
                    );
                  })
                ) : (
                  <Typography variant="body1" color="textSecondary"> No categories available.</Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CategoryPopUp;
