import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import apiUrl from '../../config';
import ViewMoreDetails from '../../popup/MaidPopUps/ViewMoreDetails';
import { Box, Typography, Button, CircularProgress, Snackbar } from "@mui/material";
import { Context } from '../../context/Context';
import { Star } from 'lucide-react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import HeartAnimation from "../Animations/Heartanimation";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import useResponsive from '../../hooks/useResponsive';
import ServiceStepsPopUp from '../../popup/ServiceStepsPopUp';

const Maid = ({ selectedSubService }) => {
    const { favoriteWorkers, userToken, getFavouriteWorkers } = useContext(Context);
    const [filteredWorkers, setFilteredWorkers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [showMaidDetails, setShowMaidDetails] = useState(false);
    const [selectedMaid, setSelectedMaid] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [miniServices, SetMiniServices] = useState([])
    const [openStepsPopUp, setOpenStepsPopUp] = useState(false)
    const [selectedMiniService, setSelectMiniService] = useState()
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { isSmallScreen,isMediumScreen } = useResponsive()
    const maidDetails = JSON.parse(localStorage.getItem("maidDetails")) || {};

    const handleHeartClick = async (worker) => {
        const isFavorited = favoriteWorkers?.some(favWorker => favWorker.id === worker.applicationUser.id);
        const newFavoritedStatus = !isFavorited;

        try {
            const apiEndpoint = newFavoritedStatus
                ? `users/addfavoriteworker?workerId=${worker.applicationUser.id}`
                : `users/deletefavoriteworker?workerId=${worker.applicationUser.id}`;

            const response = await axios({
                method: newFavoritedStatus ? 'post' : 'delete',
                url: `${apiUrl}${apiEndpoint}`,
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });

            if (response.data.success) {
                getFavouriteWorkers();
                setSnackbarMessage(newFavoritedStatus ? 'Pinned to favorites!' : 'Unpinned from favorites!');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error while updating favorite status', error);
            setSnackbarMessage('Something went wrong. Please try again.');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const handleOpenMaidDetails = (worker) => {
        const existingMaidDetails = JSON.parse(localStorage.getItem("maidDetails")) || {};
        localStorage.setItem("maidDetails", JSON.stringify({ ...existingMaidDetails, workerUserId: worker.applicationUser }));
        setSelectedMaid(worker);
        setShowMaidDetails(true);
    };

    const handleCloseMaidDetails = () => {
        setShowMaidDetails(false);
    };

    const handleOpenStepsPopUp = (miniService) => {
        setOpenStepsPopUp(true)
        setSelectMiniService(miniService)
    }

    const handleCloseStepsPopUp = () => {
        setOpenStepsPopUp(false)
    }

    const fetchWorkers = async (subServiceId) => {
        if (loading || !hasMore) return;
        setLoading(true);

        try {
            const response = await axios.post(`${apiUrl}users/searchusers`, {
                subServiceId: subServiceId,
                pageSize: 10,
                pageNumber,
            });

            const newWorkers = response.data.users || [];

            const combinedWorkers = [...filteredWorkers, ...newWorkers];

            const uniqueWorkers = [
                ...new Map(combinedWorkers.map(worker => [worker.applicationUser.id, worker])).values(),
            ];

            if (uniqueWorkers.length === 0) {
                setHasMore(false);
            } else {
                setFilteredWorkers(uniqueWorkers);
            }
        } catch (error) {
            console.error('Error fetching workers:', error);
        } finally {
            setLoading(false);
        }
    };


    const fetchWorkersDebounced = debounce(() => fetchWorkers(maidDetails?.subCategoryId?.id), 1000);

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 100
        ) {
            setPageNumber(prev => prev + 1);
            fetchWorkersDebounced();
        }
    };

    const getMiniServices = async (subServiceId) => {
        try {
            const response = await axios.get(apiUrl + `servicetypes/getminiservices?subServiceId=${subServiceId}`)
            SetMiniServices(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchWorkers(selectedSubService?.id)
        getMiniServices(selectedSubService?.id)
    }, [selectedSubService?.id])

    useEffect(() => {
        getMiniServices(maidDetails?.subCategoryId?.id)
    }, [])

    useEffect(() => {
        getFavouriteWorkers();
    }, [pageNumber]);

    useEffect(() => {
        fetchWorkers(maidDetails?.subCategoryId?.id);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const isFavoriteWorker = (workerId) => {
        return favoriteWorkers.some(favWorker => favWorker.id === workerId);
    };

    const filteredWorkersWithFavorites = filteredWorkers.map(worker => ({
        ...worker,
        isFavorite: isFavoriteWorker(worker.applicationUser.id),
    }));

    return (
        <Box>
            {loading && pageNumber === 1 ? (
                <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", height: "90vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography variant='h5' sx={{
                        position: isSmallScreen ? 'none' : isMediumScreen ? 'relative' : 'relative',
                        // top: "70px",
                        top: isSmallScreen ? 'auto' : isMediumScreen ? 'auto' : '70px'
                    }}>Recommended Maid’s</Typography>
                    <Box sx={{
                        mt: 11, maxHeight: 'calc(100vh - 130px)',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': { display: 'none' },
                    }}>
                        {miniServices.length > 0 ? (
                            <Box>
                                <Typography variant='h6'>Mini Services</Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                    {miniServices.map((service, index) => (
                                        <Box sx={{
                                            marginBottom: 2, display: "flex", alignItems: "center", border: "1px solid #E4E4E4", borderRadius: "10px", padding: "10px", justifyContent: "space-between", width: "90%", mt: 1
                                        }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <img src={`http://api.sevaki.in/${service.imagePath}`} alt={service.name} style={{ width: '90px', height: '90px', borderRadius: '10px' }} />
                                                <Box sx={{ marginLeft: 2, width: '100%' }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: '5px', pb: '3px', borderBottom: '1.5px dashed #BEBEBE' }}>
                                                        <Box>
                                                            <Typography variant='h5' sx={{ color: '#7E60BF' }}>{service.name}</Typography>
                                                            <Typography variant='body2' sx={{ color: "#000000" }}>{service.description}</Typography>
                                                        </Box>
                                                        <Box sx={{ display: isSmallScreen ? "block" : "flex", alignItems: "center", gap: 2 }}>
                                                            <Button sx={{ border: "1px solid #7E60BF", backgroundColor: "#f0ebfb", width: "90px", height: "35px", ml: 1 }} onClick={() => handleOpenStepsPopUp(service)}> Add</Button>

                                                        </Box>

                                                    </Box>

                                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: '5px' }}>
                                                        <Typography variant='body1' sx={{ color: "#000000", fontWeight: 'bold' }}>₹{service.basePrice}  </Typography>
                                                        <Typography variant='body1' sx={{ color: "#BEBEBE" }}>•</Typography>
                                                        <Typography variant='body1' sx={{ color: "#BEBEBE" }}>Expected: {service.expectedHours}Hr</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {/* <Box sx={{ display: isSmallScreen ? "block" : "flex", alignItems: "center", gap: 2 }}>
                                                <Button sx={{ border: "1px solid #7E60BF", backgroundColor: "#f0ebfb", width: "90px", height: "35px", ml: 1 }} onClick={() => handleOpenStepsPopUp(service)}> Add</Button>

                                            </Box> */}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ) : (
                            <Box>
                                {filteredWorkersWithFavorites.length > 0 ? (
                                    filteredWorkersWithFavorites.map((worker) => (
                                        <Box key={worker.id}>
                                            <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center", border: "1px solid #E4E4E4", borderRadius: "10px", 
                                                padding: "10px", justifyContent: "space-between", width: "90%",mt: isSmallScreen ? 'auto' : isMediumScreen ? 'auto' : 1}}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={worker.profilePicture} alt={worker.name} style={{ width: '100px', height: '100px', borderRadius: '10px' }} />
                                                    <Box sx={{ marginLeft: 2 }}>
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <Typography variant='h5'>{worker.applicationUser.firstName} {worker.applicationUser.lastName}</Typography>
                                                            <VerifiedUserIcon sx={{ paddingLeft: "10px", color: "success.main" }} />
                                                        </Box>
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <Star color="#FFB700" fill="#FFB700" height={16} width={16} />
                                                            <Typography variant='body1' sx={{ color: "#BCBCBC", paddingLeft: "10px" }}>4.5 Rating</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: isSmallScreen ? "block" : "flex", alignItems: "center", gap: 2 }}>
                                                    <Button sx={{ border: "1px solid #7E60BF", backgroundColor: "#f0ebfb", width: "90px", height: "35px", ml: 1 }} onClick={() => handleOpenMaidDetails(worker)}> Add</Button>
                                                    <Box sx={{
                                                        width: '50px',
                                                        height: '35px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: isSmallScreen ? 'center' : 'flex-start',
                                                        width: isSmallScreen ? 'auto' : '50px',
                                                        height: isSmallScreen ? 'auto' : '35px',
                                                    }}>
                                                        <Button
                                                            onClick={() => handleHeartClick(worker)}
                                                            startIcon={
                                                                worker.isFavorite ? (
                                                                    <FavoriteIcon color="error" sx={{ fontSize: 32, width: '32px', height: '32px' }} />
                                                                ) : (
                                                                    <FavoriteBorderIcon sx={{ fontSize: 32, width: '32px', height: '32px' }} />
                                                                )
                                                            }
                                                            disableRipple
                                                            sx={{
                                                                width: '50%',
                                                                height: '100%',
                                                                boxShadow: 'none',
                                                                '&:hover': {
                                                                    backgroundColor: 'transparent',
                                                                    boxShadow: 'none',
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "400px" }}>
                                        No profiles are available based on your preference.
                                    </Typography>
                                )}
                            </Box>
                        )}
                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={2000}
                            onClose={handleCloseSnackbar}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', backgroundColor: '#E7F0DC', color: 'white' }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', height: '50px', width: '280px' }}>
                                <HeartAnimation triggerAnimation={snackbarOpen} />
                                <p style={{ color: 'black', width: '500px' }}>{snackbarMessage}</p>
                            </div>
                        </Snackbar>
                    </Box>
                    {loading && <CircularProgress />}
                    <ViewMoreDetails open={showMaidDetails} onClose={handleCloseMaidDetails} maid={selectedMaid?.applicationUser} />
                    {openStepsPopUp && <ServiceStepsPopUp open={openStepsPopUp} onClose={handleCloseStepsPopUp} service={selectedMiniService} />}
                </>
            )}
        </Box>

    );
};

export default Maid;
