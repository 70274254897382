import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardActions, Button, Typography, Box, Chip, Divider, CircularProgress } from '@mui/material';
import Container from '@mui/material/Container';
import useResponsive from '../hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import axios from 'axios';
import apiUrl from "../config";
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import BottomNavBar from '../components/BottomNavbar';

const Membership = () => {
    const [plans, setPlans] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                setLoading(true);
                const response = await axios.post(apiUrl + 'usermembershipplan/listmembershipplans?zoneId=247');
                setPlans(response.data?.plans || []);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch membership plans. Please try again later.');
                setLoading(false);
                console.error(err);
            }
        };

        fetchPlans();
    }, []);

    const navigate = useNavigate();
    const handleNavigateContact = () => { navigate("/contactus"); };
    const { isSmallScreen, isMediumScreen } = useResponsive();

    return (
        <>
            <Navbar />
            <BottomNavBar />

            <Container>
                <Box sx={{ textAlign: 'center', margin: '7rem 0 ', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '20px' }}>
                    <Typography variant="h2" fontWeight={700} lineHeight={1}>
                        Find the <span style={{ color: '#7E60BF' }}>Right Plan.</span>
                    </Typography>
                    <Typography variant="body1" maxWidth={550} color="#BCBCBC">
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                    </Typography>
                </Box>

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                        <CircularProgress color="primary" />
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="center" flexWrap="wrap" gap='5px' >
                        {plans.map((plan, index) => (
                            <>
                                <Card key={index} sx={{
                                    bgcolor: index === 1 ? '#7E60BF' : '#FFF',
                                    color: index === 1 ? 'white' : 'text.primary', margin: '10px',
                                    // gap:'5px',
                                    marginTop: plan.heading === 'Professional' ? '-5px' : '20px',
                                    // transform: plan.heading === 'Professional' ? 'translateY(-10px)' : 'none', 
                                    width: isSmallScreen ? '100%' : isMediumScreen ? '100%' : '31%',
                                    padding: '20px', boxSizing: 'border-box',
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'space-between', borderRadius: '15px', boxShadow: 'none',
                                    border: '1px solid #E4E4E4', overflow: 'visible'
                                }}
                                >
                                    {plan.heading === 'Professional' && (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '0px', position: 'relative', }}  >
                                            <Chip label="Most Popular" color="primary" sx={{
                                                bgcolor: '#FFB700', color: 'black', fontWeight: 'bold', position: 'absolute', width: '140px', height: '40px', top: '-41px', left: '50%', transform: 'translateX(-50%)', borderRadius: '20px', border: '2px solid white',
                                                //  zIndex: 1,
                                            }}
                                            />
                                        </Box>

                                    )}


                                    {/* Plan Content */}
                                    <CardContent>
                                        <Typography variant="h4" sx={{ margin: '0 0 25px 0' }} > {plan.heading} </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: '10px', margin: '20px 0 10px 0', }}  >
                                            <Typography variant="h3" fontWeight={700} sx={{ color: index === 1 ? 'white' : '#7E60BF', }} > ₹{plan.price} </Typography>
                                            <Typography sx={{ color: index === 1 ? 'white' : 'black', }}>{plan.durationInMonths}  Months</Typography>
                                        </Box>
                                        <Typography variant="body2" color={index === 1 ? 'white' : 'black'}> {plan.caption} </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px', }}  >
                                            <CircleIcon sx={{ fontSize: '7px', color: '#E4E4E4', }} />
                                            <Divider sx={{ borderWidth: '0.5px', borderColor: '#E4E4E4', width: '97%', }} />
                                            <CircleIcon sx={{ fontSize: '7px', color: '#E4E4E4', }} />
                                        </Box>
                                        <Typography variant="h5" mt={2} fontWeight={600}>  Overview</Typography>
                                        <Box sx={{
                                            color: index === 1 ? 'white' : 'black',
                                            '& ul': {
                                                margin: '0 13px 0 0', padding: 0
                                            },
                                            '& li': {
                                                margin: '8px 0',
                                            },
                                        }}
                                        >  <div style={{ margin: '10px 7px 10px 18px', padding: 0 }} dangerouslySetInnerHTML={{ __html: plan.description }} />
                                        </Box>
                                    </CardContent>

                                    <CardActions
                                        sx={{ display: 'flex', justifyContent: 'center', height: "55px", alignItems: "center" }}
                                    >
                                        <Button variant="contained" sx={{
                                            boxShadow: 'none', width: '180px', borderRadius: '20px', backgroundColor: index === 1 ? 'white' : '#FFF',
                                            color: index === 1 ? '#7E60BF' : 'black', fontWeight: '800', '&:hover': { boxShadow: 'none' }, border: index === 1 ? '1px solid #FFF' : '1px solid black',
                                        }} >  Get Started</Button>
                                    </CardActions>
                                </Card>
                            </>


                        ))}

                    </Box>
                )}

                <Box sx={{
                    border: '1px solid #7E60BF',
                    borderRadius: '8px',
                    padding: isSmallScreen ? '30px' : '30px 50px',
                    backgroundColor: '#f0ebfb',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: isSmallScreen ? '10px' : '50px',
                    flexDirection: isSmallScreen ? 'column' : '',
                }}
                >
                    <Box>
                        <Typography variant="h5" sx={{ color: '#7E60BF', fontWeight: 'bold' }} >  For payment queries?  </Typography>
                        <Typography variant="h5" sx={{ color: '#7E60BF', fontWeight: 'bold' }}  >  Contact us. </Typography>
                        <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '10px', maxWidth: '500px', }} >  Contrary to popular belief, Lorem Ipsum is not simply random text. </Typography>
                    </Box>
                    <Button variant="contained" sx={{
                        backgroundColor: '#7E60BF', color: '#fff', padding: '10px 20px', borderRadius: '20px', textTransform: 'none', fontWeight: 'bold', margin: '30px 0',
                    }}
                        onClick={handleNavigateContact}  disableRipple disableFocusRipple   > Contact Now</Button>
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default Membership;



