import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Select, InputBase, Drawer, List, ListItem, ListItemText, Container, TextField, InputAdornment, Button } from '@mui/material';
import { ShoppingCartIcon, CircleUserIcon, SearchIcon, ChevronDownIcon, Menu as MenuIcon, MapPin } from 'lucide-react';
import useResponsive from '../hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import Loginsignup from '../popup/Loginsignup';
import ProfileMenu from '../popup/ProfileMenu';
import TrendingSearch from '../popup/TrendingSearch';
import LocationPopup from '../popup/LocationPopup';
import MiniCart from "../pages/MiniCart"
import sevakilogo from '../Assets/Images/Logo.svg'
import { Context } from '../context/Context';
import { useLocation } from "react-router-dom";


const Navbar = () => {
    const { isSmallScreen, isMediumScreen } = useResponsive();
    const { isUserLoggedIn, openLogin, setOpenLogin, setIsUserLoggedIn, userCurrentLocation } = useContext(Context);
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState('Home');
    const [openTrending, setOpenTrending] = useState(false)
    const [openLocation, setOpenLocation] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname;

    const navigate = useNavigate();

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenTrending = () => {
        setOpenTrending(true)
    }

    const handleCloseTrending = () => {
        setOpenTrending(false)
    }

    const handleOpenLocation = () => {
        setOpenLocation(true)
    }

    const handleCloseLocation = () => {
        setOpenLocation(false)
    }

    const handleDrawer = () => {
        if (isUserLoggedIn) {
            setIsDrawerOpen(true);
        } else {
            setOpenLogin(true)
        }
    };


    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const handleLoginClose = () => {
        setOpenLogin(false)
    }

    const handleMenuOpen = (event) => {
        if (isUserLoggedIn) {
            setAnchorEl(event.currentTarget);
        } else {
            setOpenLogin(true)
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userToken")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("tokenTimestamp")
        setIsUserLoggedIn(false)
        setAnchorEl(null);
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };


    const handleNavigate = (route, menuItem) => {
        if (["Home", "Store", "Membership"].includes(menuItem)) {
            setActiveMenu(menuItem);
        } else {
            setActiveMenu(null);
        }
        navigate(route);
    };
    const drawerList = () => (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button onClick={() => handleNavigate("/", 'Home')}>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button onClick={() => handleNavigate('/store', 'Store')}>
                    <ListItemText primary="Store" />
                </ListItem>
                <ListItem button onClick={() => handleNavigate("/membership", 'Membership')}>
                    <ListItemText primary="Membership" />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <Box sx={{ display: isSmallScreen ? "none" : "block" }}>
                <Box sx={{ position: 'fixed', top: 0, left: '50%', transform: 'translateX(-50%)', width: '100%', maxWidth: '1400px', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'inherit', borderBottom: '1px solid #BCBCBC' }}>
                    <AppBar position="static" color="inherit" elevation={0} sx={{ width: '100%' }} >
                        <Container sx={{ paddingLeft: '16px', paddingRight: '16px', }} >
                            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4px 0 !important', }}  >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={() => navigate('/')} sx={{ padding: 0, minWidth: 'auto' }} disableRipple disableFocusRipple>
                                        <img src={sevakilogo} style={{ width: "120px" }} alt="Sevaki Logo" />
                                    </Button>
                                    <Box sx={{ display: isSmallScreen || isMediumScreen ? "none" : 'flex', alignItems: 'center', ml: 4 }}>
                                        <Typography variant="h6" sx={{
                                            color: currentPath === "/" ? "#7E62CC" : "#c0c0c0",
                                            fontWeight: currentPath === "/" ? "bold" : "normal", mx: 2, cursor: "pointer", position: "relative",
                                            "&::after":
                                                currentPath === "/"
                                                    ? {
                                                        content: '""', position: "absolute", width: "100%", height: "4px", backgroundColor: "#7E62CC", bottom: "-23px", left: "0%", borderTopRightRadius: "10px", borderTopLeftRadius: "10px",
                                                    } : "none",
                                        }} onClick={() => handleNavigate("/", "Home")} > Home </Typography>
                                        <Typography variant="h6" sx={{
                                            color: currentPath === "/store" ? "#7E62CC" : "#c0c0c0",
                                            fontWeight: currentPath === "/store" ? "bold" : "normal", mx: 2, cursor: "pointer", position: "relative",
                                            "&::after":
                                                currentPath === "/store"
                                                    ? {
                                                        content: '""', position: "absolute", width: "100%", height: "4px",
                                                        backgroundColor: "#7E62CC", bottom: "-23px", left: "0%", borderTopRightRadius: "10px", borderTopLeftRadius: "10px",
                                                    }
                                                    : "none",
                                        }} onClick={() => handleNavigate("/store", "Store")}
                                        > Store
                                        </Typography>
                                        <Typography variant="h6" sx={{
                                            color: currentPath === "/membership" ? "#7E62CC" : "#c0c0c0",
                                            fontWeight: currentPath === "/membership" ? "bold" : "normal",
                                            mx: 2, cursor: "pointer", position: "relative",
                                            "&::after":
                                                currentPath === "/membership"
                                                    ? {
                                                        content: '""', position: "absolute", width: "100%", height: "4px",
                                                        backgroundColor: "#7E62CC", bottom: "-23px",
                                                        left: "0%", borderTopRightRadius: "10px", borderTopLeftRadius: "10px",
                                                    } : "none",
                                        }} onClick={() => handleNavigate("/membership", "Membership")}
                                        > Membership
                                        </Typography>
                                    </Box>
                                </Box>
                                {!(isSmallScreen || isMediumScreen) && (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            placeholder="Location"
                                            size="small"
                                            variant="outlined"
                                            value={userCurrentLocation.description}
                                            onClick={handleOpenLocation}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MapPin size={18} color="black" />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ChevronDownIcon size={18} color="black" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton color="inherit" onClick={handleOpenTrending}> <SearchIcon size={15} /> </IconButton>
                                            <IconButton color="inherit" onClick={handleDrawer}> <ShoppingCartIcon size={15} /> </IconButton>
                                            <MiniCart isDrawerOpen={isDrawerOpen} onClose={handleDrawerClose} />
                                            <IconButton color="inherit" onClick={handleMenuOpen}> <CircleUserIcon size={15} /> </IconButton>
                                        </Box>
                                    </Box>
                                )}

                                {(isSmallScreen || isMediumScreen) && (
                                    <IconButton onClick={toggleDrawer(true)} color="inherit"> <MenuIcon size={24} /> </IconButton>
                                )}
                            </Toolbar>
                        </Container>
                    </AppBar>

                    <ProfileMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleProfileMenuClose} setLogout={handleLogout} />
                    {!isUserLoggedIn && <Loginsignup open={openLogin} onClose={handleLoginClose} />}
                    {openTrending && <TrendingSearch open={openTrending} onClose={handleCloseTrending} />}
                    {openLocation && <LocationPopup open={openLocation} onClose={handleCloseLocation} />}
                </Box>
            </Box>
        </>
    );
};

export default Navbar;











// import React, { useContext, useState } from 'react';
// import { AppBar, Toolbar, Typography, Box, IconButton, Select, InputBase, Drawer, List, ListItem, ListItemText, Container, TextField, InputAdornment } from '@mui/material';
// import { ShoppingCartIcon, CircleUserIcon, SearchIcon, ChevronDownIcon, Menu as MenuIcon, MapPin } from 'lucide-react';
// import useResponsive from '../hooks/useResponsive';
// import { useNavigate } from 'react-router-dom';
// import Loginsignup from '../popup/Loginsignup';
// import ProfileMenu from '../popup/ProfileMenu';
// import TrendingSearch from '../popup/TrendingSearch';
// import LocationPopup from '../popup/LocationPopup';
// import MiniCart from "../pages/MiniCart"
// import sevakilogo from '../Assets/Images/Logo.svg'
// import { Context } from '../context/Context';

// const Navbar = () => {
//     const { isSmallScreen, isMediumScreen } = useResponsive();
//     const { isUserLoggedIn, openLogin, setOpenLogin, setIsUserLoggedIn } = useContext(Context)
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [drawerOpen, setDrawerOpen] = useState(false);
//     const [activeMenu, setActiveMenu] = useState('Home');
//     const [openTrending, setOpenTrending] = useState(false)
//     const [openLocation, setOpenLocation] = useState(false)
//     const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//     const navigate = useNavigate();

//     const handleProfileMenuClose = () => {
//         setAnchorEl(null);
//     };

//     const handleOpenTrending = () => {
//         setOpenTrending(true)
//     }

//     const handleCloseTrending = () => {
//         setOpenTrending(false)
//     }

//     const handleOpenLocation = () => {
//         setOpenLocation(true)
//     }

//     const handleCloseLocation = () => {
//         setOpenLocation(false)
//     }

//     const handleDrawer = () => {
//         if (isUserLoggedIn) {
//             setIsDrawerOpen(true);
//         } else {
//             setOpenLogin(true)
//         }
//     };

//     const handleDrawerClose = () => {
//         setIsDrawerOpen(false);
//     };

//     const handleLoginClose = () => {
//         setOpenLogin(false)
//     }

//     const handleMenuOpen = (event) => {
//         if (isUserLoggedIn) {
//             setAnchorEl(event.currentTarget);
//         } else {
//             setOpenLogin(true)
//         }
//     };

//     const handleLogout = () => {
//         localStorage.removeItem("userToken")
//         localStorage.removeItem("refreshToken")
//         localStorage.removeItem("tokenTimestamp")
//         setIsUserLoggedIn(false)
//         setAnchorEl(null);
//     }


//     const toggleDrawer = (open) => (event) => {
//         if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//             return;
//         }
//         setDrawerOpen(open);
//     };

//     const handleNavigate = (route, menuItem) => {
//         setActiveMenu(menuItem);
//         navigate(route);
//     };

//     const drawerList = () => (
//         <Box
//             sx={{ width: 250 }}
//             role="presentation"
//             onClick={toggleDrawer(false)}
//             onKeyDown={toggleDrawer(false)}
//         >
//             <List>
//                 <ListItem button onClick={() => handleNavigate("/", 'Home')}>
//                     <ListItemText primary="Home" />
//                 </ListItem>
//                 <ListItem button onClick={() => handleNavigate('/store', 'Store')}>
//                     <ListItemText primary="Store" />
//                 </ListItem>
//                 <ListItem button onClick={() => handleNavigate("/membership", 'Membership')}>
//                     <ListItemText primary="Membership" />
//                 </ListItem>
//             </List>
//         </Box>
//     );

//     return (
//         <>
//             <Box sx={{ borderBottom: '1px solid #BCBCBC' }}>
//                 <Container>
//                     <Box sx={{ position: 'fixed', width: '100%', top: 0, left: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}>

//                     <AppBar position="sticky" color="inherit" elevation={0} sx={{ width: '100%' }}>
//                         <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4px 0 !important', width: '100%' }}>
//                             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                                 <img src={sevakilogo} style={{ width: "120px" }} />
//                                 <Box sx={{ display: isSmallScreen || isMediumScreen ? "none" : 'flex', alignItems: 'center', ml: 4 }}>
//                                     <Typography
//                                         variant='h6'
//                                         sx={{
//                                             color: activeMenu === 'Home' ? '#7E62CC' : '#c0c0c0',
//                                             fontWeight: 'bold',
//                                             mx: 2,
//                                             cursor: 'pointer',
//                                             position: 'relative',
//                                             '&::after': activeMenu === 'Home' && {
//                                                 content: '""',
//                                                 position: 'absolute',
//                                                 width: '100%',
//                                                 height: '4px',
//                                                 backgroundColor: '#7E62CC',
//                                                 bottom: '-20px',
//                                                 left: '0%',
//                                                 borderTopRightRadius: '10px',
//                                                 borderTopLeftRadius: '10px',
//                                             },
//                                         }}
//                                         onClick={() => handleNavigate("/", 'Home')}
//                                     >
//                                         Home
//                                     </Typography>
//                                     <Typography
//                                         variant='h6'
//                                         sx={{
//                                             color: activeMenu === 'Store' ? '#7E62CC' : '#c0c0c0',
//                                             fontWeight: activeMenu === 'Store' ? 'bold' : 'normal',
//                                             mx: 2,
//                                             cursor: 'pointer',
//                                             position: 'relative',
//                                             '&::after': activeMenu === 'Store' && {
//                                                 content: '""',
//                                                 position: 'absolute',
//                                                 width: '100%',
//                                                 height: '4px',
//                                                 backgroundColor: '#7E62CC',
//                                                 bottom: '-20px',
//                                                 left: '0%',
//                                                 borderTopRightRadius: '10px',
//                                                 borderTopLeftRadius: '10px',
//                                             },
//                                         }}
//                                         onClick={() => handleNavigate("/store", 'Store')}
//                                     >
//                                         Store
//                                     </Typography>
//                                     <Typography
//                                         variant='h6'
//                                         sx={{
//                                             color: activeMenu === 'Membership' ? '#7E62CC' : '#c0c0c0',
//                                             fontWeight: activeMenu === 'Membership' ? 'bold' : 'normal',
//                                             mx: 2,
//                                             cursor: 'pointer',
//                                             position: 'relative',
//                                             '&::after': activeMenu === 'Membership' && {
//                                                 content: '""',
//                                                 position: 'absolute',
//                                                 width: '100%',
//                                                 height: '4px',
//                                                 backgroundColor: '#7E62CC',
//                                                 bottom: '-20px',
//                                                 left: '0%',
//                                                 borderTopRightRadius: '10px',
//                                                 borderTopLeftRadius: '10px',
//                                             },
//                                         }}
//                                         onClick={() => handleNavigate("/membership", 'Membership')}
//                                     >
//                                         Membership
//                                     </Typography>
//                                 </Box>
//                             </Box>

//                             {!(isSmallScreen || isMediumScreen) && (
//                                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                                     <TextField
//                                         placeholder="Location"
//                                         size='small'
//                                         variant="outlined"
//                                         sx={{
//                                             cursor: "pointer",
//                                             '& .MuiOutlinedInput-root': {
//                                                 '& fieldset': {
//                                                     borderColor: '#c0c0c0',
//                                                     border: '1px solid #c0c0c0',
//                                                     borderRadius: '5px'
//                                                 },
//                                                 '&:hover fieldset': {
//                                                     borderColor: '#c0c0c0',
//                                                     border: '1px solid #c0c0c0',
//                                                     borderRadius: '5px'
//                                                 },
//                                                 '&.Mui-focused fieldset': {
//                                                     borderColor: '#c0c0c0',
//                                                     border: '1px solid #c0c0c0',
//                                                     borderRadius: '5px'
//                                                 },
//                                             },
//                                             marginRight: '10px'
//                                         }}
//                                         InputProps={{
//                                             startAdornment: (
//                                                 <InputAdornment position="start" sx={{ display: 'flex', alignItems: 'center' }}>
//                                                     <MapPin size={18} color='black' style={{ paddingRight: '3px' }} />
//                                                 </InputAdornment>
//                                             ),
//                                             endAdornment: (
//                                                 <InputAdornment position="end" sx={{ display: 'flex', alignItems: 'center' }}>
//                                                     <ChevronDownIcon size={18} color='black' />
//                                                 </InputAdornment>
//                                             ),
//                                             inputComponent: InputBase,
//                                         }}
//                                         onClick={handleOpenLocation}
//                                     />
//                                     <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                                         <IconButton color="inherit" onClick={handleOpenTrending}>
//                                             <SearchIcon size={15} />
//                                         </IconButton>
//                                         <IconButton color="inherit">
//                                             <ShoppingCartIcon size={15} onClick={handleDrawer} />
//                                         </IconButton>
//                                         <MiniCart isDrawerOpen={isDrawerOpen} onClose={handleDrawerClose} />
//                                         <IconButton color="inherit" onClick={handleMenuOpen}>
//                                             <CircleUserIcon size={15} />
//                                         </IconButton>
//                                     </Box>
//                                 </Box>
//                             )}

//                             {(isSmallScreen || isMediumScreen) && (
//                                 <IconButton onClick={toggleDrawer(true)} color="inherit">
//                                     <MenuIcon size={24} />
//                                 </IconButton>
//                             )}
//                         </Toolbar>

//                         <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//                             {drawerList()}
//                         </Drawer>

//                         <ProfileMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleProfileMenuClose} setLogout={handleLogout} />
//                         {!isUserLoggedIn && <Loginsignup open={openLogin} onClose={handleLoginClose} />}
//                         {openTrending && <TrendingSearch open={openTrending} onClose={handleCloseTrending} />}
//                         {openLocation && <LocationPopup open={openLocation} onClose={handleCloseLocation} />}

//                     </AppBar>
//                     </Box>
//                 </Container>
//             </Box>
//         </>
//     );
// };

// export default Navbar;








// import React, { useContext, useState } from 'react';
// import { AppBar, Toolbar, Typography, Box, IconButton, Select, InputBase, Drawer, List, ListItem, ListItemText, Container, TextField, InputAdornment } from '@mui/material';
// import { ShoppingCartIcon, CircleUserIcon, SearchIcon, ChevronDownIcon, Menu as MenuIcon, MapPin } from 'lucide-react';
// import useResponsive from '../hooks/useResponsive';
// import { useNavigate } from 'react-router-dom';
// import Loginsignup from '../popup/Loginsignup';
// import ProfileMenu from '../popup/ProfileMenu';
// import TrendingSearch from '../popup/TrendingSearch';
// import LocationPopup from '../popup/LocationPopup';
// import MiniCart from "../pages/MiniCart"
// import sevakilogo from '../Assets/Images/Logo.svg'
// import { Context } from '../context/Context';

// const Navbar = () => {
//     const { isSmallScreen, isMediumScreen } = useResponsive();
//     const { isUserLoggedIn, openLogin, setOpenLogin, setIsUserLoggedIn } = useContext(Context)
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [drawerOpen, setDrawerOpen] = useState(false);
//     const [activeMenu, setActiveMenu] = useState('Home');
//     const [openTrending, setOpenTrending] = useState(false)
//     const [openLocation, setOpenLocation] = useState(false)
//     const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//     const navigate = useNavigate();

//     const handleProfileMenuClose = () => {
//         setAnchorEl(null);
//     };

//     const handleOpenTrending = () => {
//         setOpenTrending(true)
//     }

//     const handleCloseTrending = () => {
//         setOpenTrending(false)
//     }

//     const handleOpenLocation = () => {
//         setOpenLocation(true)
//     }

//     const handleCloseLocation = () => {
//         setOpenLocation(false)
//     }

//     const handleDrawer = () => {
//         if (isUserLoggedIn) {
//             setIsDrawerOpen(true);
//         } else {
//             setOpenLogin(true)
//         }
//     };

//     const handleDrawerClose = () => {
//         setIsDrawerOpen(false);
//     };

//     const handleLoginClose = () => {
//         setOpenLogin(false)
//     }

//     const handleMenuOpen = (event) => {
//         if (isUserLoggedIn) {
//             setAnchorEl(event.currentTarget);
//         } else {
//             setOpenLogin(true)
//         }
//     };

//     const handleLogout = () => {
//         localStorage.removeItem("userToken")
//         localStorage.removeItem("refreshToken")
//         localStorage.removeItem("tokenTimestamp")
//         setIsUserLoggedIn(false)
//         setAnchorEl(null);
//     }


//     const toggleDrawer = (open) => (event) => {
//         if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//             return;
//         }
//         setDrawerOpen(open);
//     };

//     const handleNavigate = (route, menuItem) => {
//         setActiveMenu(menuItem);
//         navigate(route);
//     };

//     const drawerList = () => (
//         <Box
//             sx={{ width: 250 }}
//             role="presentation"
//             onClick={toggleDrawer(false)}
//             onKeyDown={toggleDrawer(false)}
//         >
//             <List>
//                 <ListItem button onClick={() => handleNavigate("/", 'Home')}>
//                     <ListItemText primary="Home" />
//                 </ListItem>
//                 <ListItem button onClick={() => handleNavigate('/store', 'Store')}>
//                     <ListItemText primary="Store" />
//                 </ListItem>
//                 <ListItem button onClick={() => handleNavigate("/membership", 'Membership')}>
//                     <ListItemText primary="Membership" />
//                 </ListItem>
//             </List>
//         </Box>
//     );

//     return (
//         <>
//             <Box sx={{ borderBottom: '1px solid #BCBCBC' }}>
//                 <Container>
//                     <AppBar position="sticky" color="inherit" elevation={0} sx={{ width: '100%' }}>
//                         <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4px 0 !important', width: '100%' }}>
//                             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                                 <img src={sevakilogo} style={{ width: "120px" }} />
//                                 <Box sx={{ display: isSmallScreen || isMediumScreen ? "none" : 'flex', alignItems: 'center', ml: 4 }}>
//                                     <Typography
//                                         variant='h6'
//                                         sx={{
//                                             color: activeMenu === 'Home' ? '#7E62CC' : '#c0c0c0',
//                                             fontWeight: 'bold',
//                                             mx: 2,
//                                             cursor: 'pointer',
//                                             position: 'relative',
//                                             '&::after': activeMenu === 'Home' && {
//                                                 content: '""',
//                                                 position: 'absolute',
//                                                 width: '100%',
//                                                 height: '4px',
//                                                 backgroundColor: '#7E62CC',
//                                                 bottom: '-20px',
//                                                 left: '0%',
//                                                 borderTopRightRadius: '10px',
//                                                 borderTopLeftRadius: '10px',
//                                             },
//                                         }}
//                                         onClick={() => handleNavigate("/", 'Home')}
//                                     >
//                                         Home
//                                     </Typography>
//                                     <Typography
//                                         variant='h6'
//                                         sx={{
//                                             color: activeMenu === 'Store' ? '#7E62CC' : '#c0c0c0',
//                                             fontWeight: activeMenu === 'Store' ? 'bold' : 'normal',
//                                             mx: 2,
//                                             cursor: 'pointer',
//                                             position: 'relative',
//                                             '&::after': activeMenu === 'Store' && {
//                                                 content: '""',
//                                                 position: 'absolute',
//                                                 width: '100%',
//                                                 height: '4px',
//                                                 backgroundColor: '#7E62CC',
//                                                 bottom: '-20px',
//                                                 left: '0%',
//                                                 borderTopRightRadius: '10px',
//                                                 borderTopLeftRadius: '10px',
//                                             },
//                                         }}
//                                         onClick={() => handleNavigate("/store", 'Store')}
//                                     >
//                                         Store
//                                     </Typography>
//                                     <Typography
//                                         variant='h6'
//                                         sx={{
//                                             color: activeMenu === 'Membership' ? '#7E62CC' : '#c0c0c0',
//                                             fontWeight: activeMenu === 'Membership' ? 'bold' : 'normal',
//                                             mx: 2,
//                                             cursor: 'pointer',
//                                             position: 'relative',
//                                             '&::after': activeMenu === 'Membership' && {
//                                                 content: '""',
//                                                 position: 'absolute',
//                                                 width: '100%',
//                                                 height: '4px',
//                                                 backgroundColor: '#7E62CC',
//                                                 bottom: '-20px',
//                                                 left: '0%',
//                                                 borderTopRightRadius: '10px',
//                                                 borderTopLeftRadius: '10px',
//                                             },
//                                         }}
//                                         onClick={() => handleNavigate("/membership", 'Membership')}
//                                     >
//                                         Membership
//                                     </Typography>
//                                 </Box>
//                             </Box>

//                             {!(isSmallScreen || isMediumScreen) && (
//                                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                                     <TextField
//                                         placeholder="Location"
//                                         size='small'
//                                         variant="outlined"
//                                         sx={{
//                                             cursor: "pointer",
//                                             '& .MuiOutlinedInput-root': {
//                                                 '& fieldset': {
//                                                     borderColor: '#c0c0c0',
//                                                     border: '1px solid #c0c0c0',
//                                                     borderRadius: '5px'
//                                                 },
//                                                 '&:hover fieldset': {
//                                                     borderColor: '#c0c0c0',
//                                                     border: '1px solid #c0c0c0',
//                                                     borderRadius: '5px'
//                                                 },
//                                                 '&.Mui-focused fieldset': {
//                                                     borderColor: '#c0c0c0',
//                                                     border: '1px solid #c0c0c0',
//                                                     borderRadius: '5px'
//                                                 },
//                                             },
//                                             marginRight: '10px'
//                                         }}
//                                         InputProps={{
//                                             startAdornment: (
//                                                 <InputAdornment position="start" sx={{ display: 'flex', alignItems: 'center' }}>
//                                                     <MapPin size={18} color='black' style={{ paddingRight: '3px' }} />
//                                                 </InputAdornment>
//                                             ),
//                                             endAdornment: (
//                                                 <InputAdornment position="end" sx={{ display: 'flex', alignItems: 'center' }}>
//                                                     <ChevronDownIcon size={18} color='black' />
//                                                 </InputAdornment>
//                                             ),
//                                             inputComponent: InputBase,
//                                         }}
//                                         onClick={handleOpenLocation}
//                                     />
//                                     <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                                         <IconButton color="inherit" onClick={handleOpenTrending}>
//                                             <SearchIcon size={15} />
//                                         </IconButton>
//                                         <IconButton color="inherit">
//                                             <ShoppingCartIcon size={15} onClick={handleDrawer} />
//                                         </IconButton>
//                                         <MiniCart isDrawerOpen={isDrawerOpen} onClose={handleDrawerClose} />
//                                         <IconButton color="inherit" onClick={handleMenuOpen}>
//                                             <CircleUserIcon size={15} />
//                                         </IconButton>
//                                     </Box>
//                                 </Box>
//                             )}

//                             {(isSmallScreen || isMediumScreen) && (
//                                 <IconButton onClick={toggleDrawer(true)} color="inherit">
//                                     <MenuIcon size={24} />
//                                 </IconButton>
//                             )}
//                         </Toolbar>

//                         <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//                             {drawerList()}
//                         </Drawer>

//                         <ProfileMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleProfileMenuClose} setLogout={handleLogout} />
//                         {!isUserLoggedIn && <Loginsignup open={openLogin} onClose={handleLoginClose} />}
//                         {openTrending && <TrendingSearch open={openTrending} onClose={handleCloseTrending} />}
//                         {openLocation && <LocationPopup open={openLocation} onClose={handleCloseLocation} />}

//                     </AppBar>
//                 </Container>
//             </Box>
//         </>
//     );
// };

// export default Navbar;
