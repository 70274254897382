import React, { useState, useContext } from "react";
import { Box, Typography, IconButton, Menu, MenuItem, } from "@mui/material";
import { Plus, EllipsisVertical, Edit, Trash2 } from "lucide-react";
import AddressPopup from "../../../popup/AddressPopup";
import apiUrl from "../../../config";
import axios from "axios";
import { Context } from "../../../context/Context";
import BottomNavBar from "../../../components/BottomNavbar";
import useResponsive from "../../../hooks/useResponsive";


const MyAddress = () => {
    const { userAddress, userToken, displayStateName } = useContext(Context);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [addresses, setAddresses] = useState(userAddress || []);
    const { isSmallScreen, isMediumScreen } = useResponsive();


    const handleMenuOpen = (event, addressType) => {
        setAnchorEl(event.currentTarget);
        setSelectedAddress(addressType);
    };

    const handleAddressSubmit = (newAddress) => {
        if (newAddress) {
            setAddresses(prevAddresses =>
                prevAddresses.map(address =>
                    address.id === newAddress.id ? newAddress : address
                )
            );
        } else {
            setAddresses(prevAddresses => [...prevAddresses, newAddress]);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedAddress(null);
    };

    const handleAddClick = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleEdit = (addressId) => {
        const addressToEdit = addresses.find(address => address.id === addressId);
        setSelectedAddress(addressToEdit);
        setIsPopupOpen(true);
    };

    const handleDelete = async (addressId) => {
        try {
            await axios.delete(`${apiUrl}users/deleteuseraddressbytoken?id=${addressId}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            setAddresses(addresses.filter(address => address.id !== addressId));
        } catch (error) {
            console.error("Failed to delete address:", error);
        }
    };



    return (
        <>

        <Box >
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer", marginBottom: "10px" , padding: isSmallScreen ? "30px" : isMediumScreen ? "16px" : "17px"}}
            >
                <Typography variant="h5">My Address</Typography>
                <Box sx={{ display: "flex", cursor: "pointer" }} onClick={handleAddClick}>
                    <Plus style={{ color: "#7E60BF", margin: "0px 6px 0px 0px" }} />
                    <Typography sx={{ color: "#7E60BF" }} variant="h5"> Add </Typography>
                </Box>
            </Box>

            <Box sx={{  padding: isSmallScreen ? "20px" : isMediumScreen ? "16px" : "17px"}}>
                {userAddress && userAddress.length > 0 ? (
                    userAddress.map((address) => (
                        <Box key={address.id} sx={{ border: "1px solid #e0e0e0", borderRadius: "8px" ,padding:'15px', marginBottom: "20px" }}>
                            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                {address.addressType_Id === 1 ? "Home" : "Office"}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant="body1">
                                    {address.address1}, {address.address2}, {address.city}, {displayStateName(address.state)}, {address.pincode}
                                </Typography>
                                <IconButton onClick={(event) => handleMenuOpen(event, address)}>
                                    <EllipsisVertical />
                                </IconButton>
                            </Box>
                        </Box>
                    ))
                ) : (
                    <Typography>No addresses found.</Typography>
                )}
            </Box>


            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{ elevation: 0, sx: { width: "130px", border: "1px solid #E4E4E4", borderRadius: "10px" } }}
            >
                <MenuItem onClick={() => handleEdit(selectedAddress.id)}>
                    <Edit size={18} style={{ marginRight: 8 }} />
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={() => handleDelete(selectedAddress.id)}
                >
                    <Trash2 size={18} style={{ marginRight: 8 }} />
                    Delete
                </MenuItem>
            </Menu>

            <AddressPopup  open={isPopupOpen}
                onClose={handleClosePopup}
                address={selectedAddress}  onAddressSubmit={handleAddressSubmit}
            />
            <BottomNavBar />
        </Box>
        </>
    );
};

export default MyAddress;