import React, { useContext, useState } from "react";
import { Box, Typography, Rating, Modal, TextField, Button, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import apiUrl from "../../config";
import { Context } from "../../context/Context";
import useResponsive from "../../hooks/useResponsive";


const ratingData = {
  experienceQuestion: "How would you rate your overall experience with the facial service?",
  cleanlinessQuestion: "How would you rate the cleanliness and hygiene of the service?",
  ratingOptions: ["Okay", "Good", "Ever Got", "Outstanding"],
  cleanlinessOptions: ["Poor", "Average", "Clean Enough", "Very Clean"],
  submitButtonText: "Submit",
};

const RatingPopup = ({ ratingValue, onClose, bookingId, targetUserId }) => {

  const { isSmallScreen, isMediumScreen } = useResponsive();
  const { userDetails, userToken } = useContext(Context)
  const [selectedRating, setSelectedRating] = useState(null);
  const [selectedCleanliness, setSelectedCleanliness] = useState(null);
  const [reviewText, setReviewText] = useState('');
  const [loading, setLoading] = useState(false);
  const [reviewType] = useState("UserReview");

  const handleRatingSelect = (rating) => {
    setSelectedRating(prev => (prev === rating ? null : rating));
  };

  const handleCleanlinessSelect = (cleanliness) => {
    setSelectedCleanliness(prev => (prev === cleanliness ? null : cleanliness));
  };

  const handleReviewTextChange = (e) => {
    setReviewText(e.target.value);
  };

  const getButtonStyle = (isSelected) => ({
    backgroundColor: isSelected ? "#E4E4E4" : "",
    color: isSelected ? "#7E60BF" : "#E4E4E4",
    fontWeight: "bold",
    borderRadius: "20px",
    padding: isSmallScreen ? "6px" : isMediumScreen ? "auto" : "auto",
    borderColor: isSelected ? "#7E60BF" : "#7E60BF",
  });

  const isSubmitEnabled = selectedRating && selectedCleanliness && reviewText;

  const handleSubmit = async () => {
    if (!isSubmitEnabled) return;

    setLoading(true);
    try {
      const response = await axios.post(apiUrl + 'reviews/createuserreview', {
        userId: userDetails.id,
        targetUserId: targetUserId,
        bookingId: bookingId,
        reviewType: reviewType,
        description: reviewText,
        ratingValue: ratingValue,
      }, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if (response.data.status = true) {
        onClose();
      }
    } catch (error) {
      console.error('API Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: "white",
          width: isSmallScreen ? "370px" : isMediumScreen ? "355x" : "600px",
          margin: "auto",
          mt: isSmallScreen ? "10rem" : isMediumScreen ? "auto" : "5%",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: 24,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" sx={{ color: "#7E60BF", fontWeight: "bold" }}>
            Rating
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{ m: "10px 5px" }} />

        <Typography sx={{ fontSize: '15px', mb: 1 }}>
          Enter your overall experience?
        </Typography>

        <TextField
          fullWidth
          placeholder="Type here...!"
          multiline
          rows={3}
          variant="outlined"
          sx={{ mb: 2 }}
          value={reviewText}
          onChange={handleReviewTextChange}
        />

        <Typography sx={{ fontSize: '15px', mb: 2 }}>
          1. {ratingData.experienceQuestion}
        </Typography>
        <Box sx={{ display: 'flex', gap: '12px' }} mb={3}>
          {ratingData.ratingOptions.map((label) => (
            <Button
              key={label}
              variant="outlined"
              sx={getButtonStyle(selectedRating === label)}
              onClick={() => handleRatingSelect(label)}
            >
              {label}
            </Button>
          ))}
        </Box>

        <Typography variant="body2" sx={{ fontSize: '15px', mb: 2 }}>
          2. {ratingData.cleanlinessQuestion}
        </Typography>
        <Box sx={{ display: 'flex', gap: '12px' }} mb={3}>
          {ratingData.cleanlinessOptions.map((label) => (
            <Button
              key={label}
              variant="outlined"
              sx={getButtonStyle(selectedCleanliness === label)}
              onClick={() => handleCleanlinessSelect(label)}
            >
              {label}
            </Button>
          ))}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: isSubmitEnabled ? "#7E60BF" : "#E4E4E4",
              color: isSubmitEnabled ? "white" : "#7E60BF",
              fontWeight: "bold",
              borderRadius: "20px",
              textTransform: "none",
              mt: 4,
              width: "160px",
            }}
            onClick={handleSubmit}
            disabled={!isSubmitEnabled || loading}
          >
            {loading ? 'Submitting...' : ratingData.submitButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RatingPopup;
