import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography, TextField, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import apiUrl from '../config';
import useResponsive from '../hooks/useResponsive';

const Verification = ({ open, setOpen }) => {
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isSmallScreen,isMediumScreen } = useResponsive()
  const handleCodeChange = (event, index) => {
    const value = event.target.value;

    if (/^[0-9]?$/.test(value)) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);

      if (value && index < 3) {
        document.getElementById(`verification-code-${index + 1}`).focus();
      } else if (!value && index > 0) {
        document.getElementById(`verification-code-${index - 1}`).focus();
      }
    }
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    const code = verificationCode.join('');
    const mobileNumber = '+919144754865';
    const sessionId = 'your-session-id';
    const fcmToken = 'your-fcm-token';

    const data = {
      mobileNumber: mobileNumber,
      code: code,
      sessionId: sessionId,
      role: 'User',
      deviceType: 'System',
      fcmToken: fcmToken,
    };
    console.log(data)
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await axios.post(apiUrl + 'users/confirmotp', data, {
        headers: {
          'accept': '*/*',
          'Content-Type': 'application/json-patch+json',
        },
      });

      if (response.data.status === true) {
        console.log('OTP Verified', response.data);
        handleClose();
      }
    } catch (error) {
      setErrorMessage('Failed to verify OTP. Please try again.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const modalStyle = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? "350px" : isMediumScreen ? "400px" : "400px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '6px',
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <IconButton
          sx={{ alignSelf: 'flex-end', position: 'absolute', right: '0px', top: '-30px', padding: '2px' }}
          onClick={handleClose}
        >
          <ClearIcon sx={{ backgroundColor: 'primary.main', color: '#fff', borderRadius: '50%', fontSize: 'medium' }} />
        </IconButton>

        <Typography variant="body" gutterBottom>
          Edit Number
        </Typography>

        <Typography variant="h5" gutterBottom>
          Verification code
        </Typography>
        <Typography variant="body1" gutterBottom>
          We have sent you a 4 digit code on +91 9144754865
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'start', mb: 2, gap: 2 }}>
          {verificationCode.map((digit, index) => (
            <TextField
              key={index}
              id={`verification-code-${index}`}
              value={digit}
              onChange={(e) => handleCodeChange(e, index)}
              inputProps={{ maxLength: 1 }}
              sx={{ width: '45px', border: '1px solid #29292933' }}
            />
          ))}
        </Box>

        {errorMessage && (
          <Typography variant="body2" sx={{ color: 'red', mb: 2 }}>
            {errorMessage}
          </Typography>
        )}

        <Typography variant="body2" sx={{ mb: 2 }}>
          <Button
            variant="text"
            component="a"
            href="#"
            sx={{ color: 'primary.main', borderBottom: '2px solid primary.main' }}
          >
            Resend code
          </Button>
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{ width: '110px', color: "#fff", backgroundColor: 'primary.main', borderRadius: '20px' }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Login'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default Verification;
