import React, { useContext, useEffect } from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import { ArrowLeftIcon } from 'lucide-react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VerifiedIcon from '@mui/icons-material/Verified';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import chefimage from "../../../Assets/Images/maidImg.png";
import axios from 'axios';
import apiUrl from '../../../config';
import { Context } from '../../../context/Context';
import Navbar from '../../../components/Navbar';
import BottomNavBar from '../../../components/BottomNavbar';
import useResponsive from '../../../hooks/useResponsive';


const FavouriteMaids = () => {
    const { isMediumScreen, isSmallScreen} = useResponsive();
    const { favoriteWorkers, getFavouriteWorkers, userToken } = useContext(Context);
    const navigate = useNavigate();

    const handleDeleteFavouriteMaid = async (maid) => {
        try {
            const response = await axios.delete(apiUrl + `users/deletefavoriteworker?workerId=${maid.id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response.data.success === true) {
                getFavouriteWorkers();
            }
        } catch (error) {
            console.error("Error removing favorite maid:", error);
        }
    };

    const handleHomeClick = () => {
        navigate('/maid');
    };

    useEffect(() => {
        getFavouriteWorkers();
    }, []);

    return (
        <>
            <Navbar />
            <BottomNavBar />

            <Container sx={{ marginTop: 11 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '280px', margin: '20px 0', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }} onClick={handleHomeClick}>
                        <ArrowLeftIcon style={{ width: '15px' }} /> Back
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: '700' }}>Favourite Maids</Typography>
                </Box>

                {favoriteWorkers?.length > 0 ? (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: isSmallScreen ? '0 0 20px 10px' : isMediumScreen ? 'auto' : '0 0 20px 130px', gap: 3 }}>
                        {favoriteWorkers?.map((maid, index) => (
                            <Box key={index} sx={{
                                display: 'flex', height: '130px', alignItems: 'center', padding: 2, borderRadius: 2, gap: '8px',
                                border: '1px solid #e0e0e0', boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', mb: 2, width: isSmallScreen ? '350px' : isMediumScreen ? '450px' : '450px'
                            }}>
                                <img src={chefimage} alt={maid.name} style={{ borderRadius: 3, width: 100, height: 100 }} />
                                <Box sx={{ flexGrow: 1, marginLeft: '15px' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginRight: 1 }}> {maid.firstName} </Typography>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginRight: 1 }}> {maid.lastName} </Typography>
                                        {maid.verified && <VerifiedIcon color="success" fontSize="small" />}
                                    </Box>
                                    <Typography color="textSecondary"> Age: {maid.age}, </Typography>
                                    <Typography color="textSecondary"> {maid.experience} years of experience </Typography>
                                    <Typography color="textSecondary">Service Area: {maid.location}</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <StarIcon color="warning" fontSize="small" /> <Typography variant="body2" sx={{ marginLeft: 0.5 }}> {maid.rating} Rating </Typography>
                                    </Box>
                                </Box>
                                <IconButton onClick={() => handleDeleteFavouriteMaid(maid)}>
                                    <FavoriteIcon sx={{ color: '#ff007f' }} />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <Typography variant="h6" color="textSecondary">No favorite maids found</Typography>
                    </Box>

                )}
            </Container>
        </>
    );
};
export default FavouriteMaids;
