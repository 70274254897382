import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, IconButton, CardContent, CardMedia, Card } from '@mui/material';
import axios from 'axios';
import apiUrl from '../config';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import threading from '../Assets/Images/threading.png'

import CloseIcon from '@mui/icons-material/Close';


import percent from '../Assets/Images/Frame.png'
const ServiceStepsPopUp = ({ open, onClose, service }) => {
    const [serviceSteps, setServiceSteps] = useState([]);

    const addOnsData = [
        {
            id: 1,
            name: 'Threading',
            price: 49,
            image: threading,
        },
        {
            id: 2,
            name: 'Facial',
            price: 299,
            image: threading,
        },
        {
            id: 3,
            name: 'Manicure',
            price: 150,
            image: threading,
        },
        {
            id: 4,
            name: 'Pedicure',
            price: 200,
            image: threading,
        },
    ];

    const getStepsForMiniService = async () => {
        try {
            const response = await axios.get(
                `${apiUrl}servicetypes/getservicedetailsteps?id=${service.id}&serviceTypes=MiniService`
            );
            setServiceSteps(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (service && open) {
            getStepsForMiniService();
        }
    }, [service, open]);

    const sortedServiceSteps = [...serviceSteps].sort((a, b) => a.id - b.id);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="service-steps-modal-title"
            aria-describedby="service-steps-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: '80vh',
                    overflow: 'auto',
                    "&::-webkit-scrollbar": {
                        display: "none", // Hide scrollbar in WebKit browsers (Chrome, Safari)
                    },
                    "-ms-overflow-style": "none", // Hide scrollbar in IE and Edge
                    "scrollbar-width": "none", // Hide scrollbar in Firefox
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        zIndex: 1,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {/* Service Details */}
                <Box sx={{ mb: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px dashed #BEBEBE' }}>
                        <Box mb={1} >
                            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                                {service.name}
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#666' }}>
                                {service.description}
                            </Typography>
                        </Box>
                        <Button variant="contained" style={{ marginLeft: '1rem' }}>
                            Added
                        </Button>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.9, mt: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Typography variant='body1' sx={{ color: "#000000", fontWeight: 'bold' }}>₹{service.basePrice}  </Typography>
                            <Typography variant='body1' sx={{ color: "#000000" }}>•</Typography>
                            <Typography variant='body1' sx={{ color: "#BEBEBE" }}> {service.expectedHours}hr | </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.3 }}>
                            <img src={percent} width={'18px'} />
                            <Typography variant="body1" color="initial"> 2%</Typography>
                        </Box>
                    </Box>

                    <Typography variant="body1" color="#0DC07B" mb={1.5} >100+ people booked last 10 days.</Typography>

                    <Box sx={{ borderTop: '1px dashed #BEBEBE', pt: 1.5 }}>
                        <Typography component="ul" sx={{ listStyleType: "disc", color: "#BEBEBE", paddingLeft: '20px' }}>
                            <Typography component="li" sx={{ fontSize: "14px", color: "#BEBEBE" }}>
                                Facial kit
                            </Typography>
                            <Typography component="li" sx={{ fontSize: "14px", color: "#BEBEBE" }}>
                                Steamer & Toner
                            </Typography>
                            <Typography component="li" sx={{ fontSize: "14px", color: "#BEBEBE" }}>
                                Cleanser & Cream
                            </Typography>
                            <Typography component="li" sx={{ fontSize: "14px", color: "#BEBEBE" }}>
                                Wooden spatula
                            </Typography>
                            <Typography component="li" sx={{ fontSize: "14px", color: "#BEBEBE" }}>
                                Mixing tray & brush
                            </Typography>
                        </Typography>
                    </Box>

                    <Box mt={5}>
                        <Box mt={1}>
                            <Typography variant="h5" color="initial">Add On's</Typography>
                            <Box
                                mt={1}
                                sx={{
                                    display: 'flex', // Display items in a row
                                    overflowX: 'auto', // Enable horizontal scroll
                                    gap: 2, // Add space between the cards
                                    paddingBottom: 2, // Add some padding to the bottom to prevent scroll cutoff
                                    '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for webkit browsers
                                    scrollbarWidth: 'none'
                                }}
                            >
                                {addOnsData.map((item) => (
                                    <Card
                                        key={item.id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: 2,
                                            borderRadius: 2,
                                            border: '1px solid lightgrey',
                                            boxShadow: 'none',
                                            minWidth: 300, // Minimum width for each card
                                            maxWidth: 350, // Maximum width for each card
                                            marginBottom: 2, // Adds space between cards vertically (if scroll is in multiple rows)
                                        }}
                                    >
                                        {/* Image Section */}
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <CardMedia
                                                component="img"
                                                image={item.image}
                                                // alt={item.name}
                                                sx={{ width: 70, height: 70, borderRadius: 1 }}
                                            />

                                            {/* Text Section */}
                                            <CardContent sx={{ flexGrow: 1, marginLeft: 2, padding: '0 !important' }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                    {item.name}
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ color: "#7E60BF", fontWeight: 'bold' }}>
                                                    ₹{item.price}
                                                </Typography>
                                            </CardContent>
                                        </Box>

                                        {/* Quantity Section */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                border: '1px solid #7E60BF',
                                                borderRadius: 1,
                                            }}
                                        >
                                            <IconButton size="small" sx={{ color: '#7E60BF' }}>
                                                <RemoveIcon />
                                            </IconButton>
                                            <Typography variant="body1" sx={{ margin: '0 4px', fontWeight: 'bold' }}>
                                                1
                                            </Typography>
                                            <IconButton size="small" sx={{ color: '#7E60BF' }}>
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                    </Card>
                                ))}
                            </Box>

                        </Box>
                    </Box>

                    <Box mt={4}>
                        <Box>
                            <Typography variant="h5" color="initial">Benifits</Typography>
                            <Typography variant="body1" color="initial">Lorem ipsum is simply a dummy text Lorem ipsum is simply a dummy text</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="h5" color="initial">Key Ingredients</Typography>
                            <Typography variant="body1" color="initial">Lorem ipsum is simply a dummy text Lorem ipsum is simply a dummy text</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="h5" color="initial">Best for</Typography>
                            <Typography variant="body1" color="initial">All Skin types</Typography>
                        </Box>
                    </Box>

                </Box>

                {/* Steps */}
                <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
                    How to Start:
                </Typography>
                <Box sx={{ position: 'relative' }}>
                    {sortedServiceSteps.map((step, index) => (
                        <Box key={step.id} sx={{ position: 'relative', mb: 1 }}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: -4,
                                    top: '18px',
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '50%',
                                    backgroundColor: '#BFBFBF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }}
                            >
                                {index + 1}
                            </Box>
                            {index !== sortedServiceSteps.length - 1 && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: '7px',
                                        top: '40px',
                                        width: '2px',
                                        height: 'calc(100% - 10px)',
                                        background: 'repeating-linear-gradient(#ccc 0px, #ccc 4px, transparent 4px, transparent 8px)',
                                    }}
                                />
                            )}
                            <Box
                                sx={{
                                    p: 2,
                                    borderRadius: 2,
                                    backgroundColor: '#f9f9f9',
                                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                                    ml: '20px',
                                }}
                            >
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {`Step ${index + 1}: ${step.title}`}
                                </Typography>
                                <Typography sx={{ color: '#666' }}>{step.description}</Typography>
                                <Box
                                    component="img"
                                    src={`http://api.sevaki.in/${step.imagePath}`}
                                    alt={step.title}
                                    sx={{
                                        width: '100%',
                                        height: '160px',
                                        borderRadius: 2,
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                        mt: 2,
                                    }}
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>

                <Box sx={{
                    textAlign: 'right', mt: 3, position: 'sticky',
                    bottom: -32, textAlign: 'center', backgroundColor: 'background.paper',
                    paddingY: 2
                }}>
                    <Button onClick={onClose} variant="contained" color="primary" >
                        Proceed
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ServiceStepsPopUp;
