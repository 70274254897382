import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { Book, Star, User, Info, Headphones, LogOut } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';

const ProfileMenu = ({ anchorEl, onClose, setLogout }) => {
  const { isSmallScreen } = useResponsive();  
  const navigate = useNavigate();

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <Menu anchorEl={anchorEl} id="account-menu" open={Boolean(anchorEl)} onClose={onClose}
      PaperProps={{
        elevation: 0, sx: { overflow: 'visible', filter: 'drop-shadow(0px 0.5px 2px rgba(0,0,0,0.10))',  marginTop: isSmallScreen ? '-64px' : '5px',
          // mt: 2,
          ml: 1, mb: isSmallScreen ? 4 : 2,  borderRadius: '10px', width: '320px',
          border: '1px solid #E4E4E4',
          '&:before': { content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
            borderTop: '1px solid #E4E4E4',
            borderLeft: '1px solid #E4E4E4',
            '@media (max-width: 600px)': {
              top: 'unset',
              bottom: -5,
              right: -5,
              transform: 'translateY(50%) rotate(45deg)',
            },
          },
        },
      }}
      MenuListProps={{
        sx: { padding: '10px', paddingTop: '10px', paddingBottom: '8px' },
      }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuItem onClick={onClose} sx={{
          '&:hover svg, &:hover p': { color: '#7E60BF' },
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        <Book size={18} style={{ marginRight: 8 }} />
        <Typography sx={{ fontSize: '15px', paddingLeft: '9px' }} onClick={() => handleNavigate('/mybookings')}> My Bookings </Typography>
      </MenuItem>
      <MenuItem onClick={onClose}
        sx={{
          '&:hover svg, &:hover p': { color: '#7E60BF' },
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        <Star size={18} style={{ marginRight: 8 }} />
        <Typography sx={{ fontSize: '15px', paddingLeft: '9px' }} onClick={() => handleNavigate('/favouritemaid')}> Favourite Maids </Typography>
      </MenuItem>
      <MenuItem onClick={onClose} sx={{
          '&:hover svg, &:hover p': { color: '#7E60BF' },
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        <User size={18} style={{ marginRight: 8 }} />
        <Typography sx={{ fontSize: '15px', paddingLeft: '9px' }} onClick={() => handleNavigate(isSmallScreen ? "/profile" : '/profile/details')}> Account Preferences</Typography>
      </MenuItem>
      <MenuItem onClick={onClose}  sx={{
          '&:hover svg, &:hover p': { color: '#7E60BF' },
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        <Info size={18} style={{ marginRight: 8 }} />
        <Typography sx={{ fontSize: '15px', paddingLeft: '9px' }} onClick={() => handleNavigate('/faq')}>
          Help Center
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={onClose}
        sx={{
          '&:hover svg, &:hover p': { color: '#7E60BF' },
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        <Headphones size={18} style={{ marginRight: 8 }} />
        <Typography sx={{ fontSize: '15px', paddingLeft: '9px' }} onClick={() => handleNavigate('/contactus')}>
          Contact Us
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={setLogout}
        sx={{
          '&:hover svg, &:hover p': {
            color: 'red',
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <LogOut size={18} style={{ marginRight: 8 }} />
        <Typography sx={{ fontSize: '15px', paddingLeft: '9px', '&:hover svg, &:hover p': {
            color: 'red',
          },
          '&:hover': {
            backgroundColor: 'transparent',
          }, }} >
          Logout
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
