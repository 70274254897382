import React, { useContext, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, useMediaQuery, styled } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

// Example image imports for each tab
import homeImage from '../Assets/Images/sevakilogo.svg';
import categoriesImage from '../Assets/Images/category.svg';
import plansImage from '../Assets/Images/plans.svg';
import profileImage from '../Assets/Images/profile.svg';
import cartImage from '../Assets/Images/cart.svg'; // Add your cart image here
import { Context } from '../context/Context';
import ServicePopup from '../pages/Home/ServicePopup';
import CategoryPopUp from '../popup/CategoryPopUp';
import FilterPopUp from '../popup/FilterPopUp';
import ProfileMenu from '../popup/ProfileMenu';

const Navbar = styled(BottomNavigation)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '80px',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'space-around',
}));

const ImageIcon = styled('img')({
  width: '24px',
  height: '24px',
});

const BottomNavBar = () => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { isUserLoggedIn, setOpenLogin, openCategoryPopUp, openFilterPopUp, setIsUserLoggedIn, getServicesFields, handleOpenServicePopup, handleOpenCategoryPopUp, category, showFilterPopUp, services } = useContext(Context);
  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("tokenTimestamp")
    setIsUserLoggedIn(false)
    setAnchorEl(null);
  }

  const handleMenuOpen = (event) => {
    if (isUserLoggedIn) {
      setAnchorEl(event.currentTarget);
    } else {
      setOpenLogin(true)
    }
  };

  return (
    <>
      <Navbar value={value} onChange={(event, newValue) => setValue(newValue)} sx={{ display: isMobile ? 'flex' : 'none', }}>
        <BottomNavigationAction
          label="Home"
          icon={<ImageIcon src={homeImage} alt="Home" />}
          onClick={() => handleNavigation('/')}
        />
        <BottomNavigationAction
          label="Categories"
          icon={<ImageIcon src={categoriesImage} alt="Categories" />}
          onClick={handleOpenServicePopup}
        />
        <BottomNavigationAction
          label="Plans"
          icon={<ImageIcon src={plansImage} alt="Plans" />}
          onClick={() => handleNavigation('/membership')}
        />
        <BottomNavigationAction
          label="Cart"
          icon={<ImageIcon src={cartImage} alt="Cart" />}
          onClick={() => handleNavigation('/cart')}
        />
        <BottomNavigationAction
          label="Profile"
          icon={<ImageIcon src={profileImage} alt="Profile" />}
          onClick={handleMenuOpen}
        />
      </Navbar>
      {getServicesFields && <ServicePopup />}
      {openCategoryPopUp && <CategoryPopUp />}
      {openFilterPopUp && <FilterPopUp />}
      <ProfileMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleProfileMenuClose} setLogout={handleLogout} />

    </>
  );
};

export default BottomNavBar;
