import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Container, Button, TextField, Grid, Card, Select, MenuItem, CardContent, Checkbox, FormControlLabel, Divider, InputLabel, ListItemText, } from "@mui/material";
import { ArrowLeft, BadgePercent, ChevronDown, ChevronRight, CircleCheck, Info, Minus, Pencil, Plus, ShieldCheck, X } from "lucide-react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import miadimg from "../Assets/Images/miad-img.png";
import Breakfastimg from "../Assets/Images/breackfast-img.png";
import dosepowder from "../Assets/Images/dosepowder.png";
import useResponsive from "../hooks/useResponsive";
import Bookingsuccessfull from "../popup/BookingPopUp/Bookingsuccessfull";
import { useNavigate } from "react-router-dom";
import AddressPopup from "../popup/AddressPopup";
import apiUrl from "../config";
import axios from "axios";
import Feilds from "../common/Feilds";
import { Context } from "../context/Context";
import Header from "../components/Header";
import UpdateMiad from '../popup/MaidPopUps/UpdateMiad'
import Reschedulepopup from "../popup/BookingPopUp/Reschedulepopup";


const Checkoutpage = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(1 || 2);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [serviceFields, setServiceFields] = useState(null);
  const [addressOpen, setAddressOpen] = useState(false);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [openReschedulePopup, setOpenReschedulePopup] = useState(false);
  const { userAddress, dynamicValues, addressTypes, userDetails, states, userToken } = useContext(Context)
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressSelected, setAddressSelected] = useState(null)
  const [maidDetails, setMaidDetails] = useState(null);
  const navigate = useNavigate()

  const handleOpenAddressPopUp = (address) => {
    setSelectedAddress(address);
    setAddressOpen(true);
  };
  const handleNavigateToMaid = () => {
    navigate("/maid"); 
  };

  const handleCloseAddressPopUp = () => { setAddressOpen(false); };
  const handleOpenReschedulePopup = () => { setOpenReschedulePopup(true); };
  const handleCloseReschedulePopup = () => { setOpenReschedulePopup(false); };
  const handleCloseSuccess = () => { setOpenSuccess(false); };
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

    const handleOpenUpdateModal = () => setOpenUpdateModal(true);
    const handleCloseUpdateModal = () => setOpenUpdateModal(false);

  const handleclickBack = () => {
    navigate("/maid")
  }
  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  const toggleAccordion = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? 1 : accordionId);
  };

  const displayStateName = (state) => {
    const stateObj = states.find((val) => val.id == state);
    return stateObj?.stateName
  }

  const createBooking = async () => {

    const expectedStartDateTime = maidDetails.ExpectedStartDateTime + "T00:00:00";
    try {
      const BookingResponse = await axios.post(apiUrl + "booking/createbooking", {
        userId: userDetails.id,
        workerUserId: maidDetails.workerUserId.id,
        serviceId: maidDetails.serviceId,
        bookingFrequencyType: maidDetails.bookingFrequencyType,
        subServiceId: maidDetails.subCategoryId.id,
        serviceAddress1: addressSelected.address1,
        serviceAddress2: addressSelected.address2,
        serviceCity: addressSelected.city,
        serviceState: addressSelected.state,
        servicePincode: addressSelected.pincode,
        serviceLandMark: addressSelected.landmark,
        serviceLatAndLong: addressSelected.latAndLong,
        isDeleted: false,
        bookingServiceCooking: [{
          ...dynamicValues,
          foodType: maidDetails.FoodType,
          cuisineType: maidDetails.CuisineTyp,
          subServiceId: maidDetails.subCategoryId.id,
          isActive: true,
          isDeleted: false
        }],
        bookingSlot: [
          {
            expectedStartDateTime: expectedStartDateTime,
            slotTimeType: 0,
            isActive: true,
            isDeleted: false
          }
        ]
      }, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });

      if (BookingResponse.data.status === true) {
        setOpenSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const storedServiceFieldsString = localStorage.getItem("serviceFields");
    if (storedServiceFieldsString) {
      const storedServiceFields = JSON.parse(storedServiceFieldsString);
      if (storedServiceFields) {
        setServiceFields([storedServiceFields]);
      } else if (Array.isArray(storedServiceFields) && storedServiceFields.length > 0 && storedServiceFields[0].title) {
        setServiceFields(storedServiceFields);
      } else {
        console.log("Unknown structure for storedServiceFields");
      }
    } else {
      console.log("No service fields found in local storage");
    }
  }, []);

  const getDropDownValues = async () => {
    if (!serviceFields || !serviceFields[0]) {
      return;
    }
    const maidDetails = JSON.parse(localStorage.getItem("maidDetails"));

    try {
      const response = await axios.get(`${apiUrl}servicefields/getservicefielddropdownsbysubserviceid?subServiceId=${maidDetails?.subCategoryId?.id}`);
      setDropdownValues(response.data.serviceFields);
    } catch (error) {
      console.error('Error fetching dropdown values:', error);
    }
  };

  useEffect(() => {
    if (serviceFields && serviceFields.length > 0) {
      getDropDownValues();
    }
  }, [serviceFields]);

  useEffect(() => {
    const maidDetailsString = localStorage.getItem("maidDetails");
    if (maidDetailsString) {
      setMaidDetails(JSON.parse(maidDetailsString));
    }
  }, []);
  return (
    <Container>
      <Header />
      <>
        <Box sx={{ mt: '65px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px", mb: 2, cursor: "pointer", }} onClick={handleclickBack} >
                <ArrowLeft sx={{ fontSize: "small" }} size={15} />
                <Typography variant="h6">Back</Typography>
              </Box>

              <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px" }} >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer", }} onClick={() => toggleAccordion(1)}>
                  <Typography variant="h5">Address</Typography>
                  <ChevronDown style={{ transform: openAccordion === 1 ? "rotate(0deg)" : "rotate(-90deg)", transition: "transform 0.3s ease", }} />
                </Box>

                <Box sx={{ maxHeight: openAccordion === 1 ? "auto" : "0px", opacity: openAccordion === 1 ? 1 : 0,
                  overflow: "hidden", height: userAddress?.length > 0 ? "40vh" : "auto",
                  transition: "max-height 0.2s ease, opacity 0.5s ease", width: '100%', mt: 2,
                  overflow: 'scroll',
                  '&::-webkit-scrollbar': { display: 'none' },
                  '-ms-overflow-style': 'none',
                  'scrollbar-width': 'none'
                }}
                >
                  {userAddress?.length > 0 ? (
                    <Box>
                      {userAddress?.map((address, index) => {
                        return (
                          <Card
                            key={index}
                            sx={{ mt: 4, display: "flex", alignItems: "center", border: "1px solid #E4E4E4", borderRadius: "10px", boxShadow: "none", }}>
                            <CardContent onClick={() => setAddressSelected(address)}
                             sx={{mt:'-48px'}} 
                            >
                              {addressSelected?.id === address?.id ? (
                                <CircleCheck fill="#7E60BF" color="white" />
                              ) : (
                                <div style={{
                                  width: '13px', height: '13px', borderRadius: '50%', border: '2px solid #E4E4E4', display: 'inline-block',
                                  // margin: "0 10px 48px 0"
                                }}
                                />
                              )}
                            </CardContent>
                            <CardContent sx={{ p: '8px' }} >
                              <Box>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                  {addressTypes.filter((val) => val.id === address.addressType_Id).map((val) => val.name)}
                                </Typography>

                              </Box>
                              <Typography variant="body2" lineHeight="16px" sx={{ color: "#000000" }}>
                                {address.address1}, {address.address2}, {address.city},{displayStateName(address.state)}, {address.landmark} {address.pincode}
                              </Typography>
                              <Box sx={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer", }} onClick={() => handleOpenAddressPopUp(address)} >
                                <Pencil size={15} fill="#7E60BF" color="#7E60BF" />
                                <Typography variant="h6" sx={{ color: "primary.main" }}>Edit</Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        );
                      })}
                      <Typography
                        variant="h6" sx={{ fontWeight: "bold", textAlign: "center", mt: 2, color: "primary.main", textDecorationLine: "underline", cursor: "pointer", }}
                        onClick={handleOpenAddressPopUp}
                      >Add Address
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "center", mt: 2, color: "primary.main", textDecorationLine: "underline", cursor: "pointer", }} onClick={handleOpenAddressPopUp} >Add Address</Typography>
                  )}
                </Box>

              </Box>

              <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px", mt: 2 }}>
                <Box sx={{
                  display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer", overflowY: 'auto',
                  '&::-webkit-scrollbar': { display: 'none' },
                }} onClick={() => toggleAccordion(2)}>
                  <Typography variant="h5">Booking Form</Typography>
                  <ChevronDown style={{ transform: openAccordion === 2 ? "rotate(0deg)" : "rotate(-90deg)", transition: "transform 0.3s ease", }} />
                </Box>
                {openAccordion === 2 && (<Box sx={{ mt: 5 }}><Feilds fields={serviceFields[0].deepFields} dropdownValues={dropdownValues} /></Box>)}
              </Box>

              <Box mt={4}>
                <Box>
                  <Typography variant="h5">Cancellation policy</Typography>
                  <Typography variant="body2" sx={{ color: "#BCBCBC" }}>Free cancellations if done more than 3 hrs before theserviceor if a professional isn’t assigned. A fee will bechargedotherwise.</Typography>
                  <Typography
                    variant="h6" sx={{ fontWeight: "bold", cursor: "pointer", mt: 1, color: "primary.main", textDecorationLine: "underline", }} >Read More</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: isSmallScreen ? "auto" : isMediumScreen ? "auto" : "480px",
                  marginTop: "10px", overflow: "scroll",
                  "&::-webkit-scrollbar": { display: "none" },
                  "-ms-overflow-style": "none",
                  "scrollbar-width": "none",
                }}
              >
                <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px 10px 0px 0px" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, }}>
                    <Typography variant="h5">Booking Details</Typography>
                    <Typography variant="h6" color="primary" sx={{ fontWeight: "bold", textDecorationLine: "underline", cursor: "pointer", }} onClick={handleOpenReschedulePopup} >Update Slot</Typography>

                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "15px", }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", }}>
                      <img src={miadimg} width="80px" height="80px" alt="not found" />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex' }}>


                          <Typography variant="h5">{maidDetails?.workerUserId?.firstName} {maidDetails?.workerUserId?.lastName}</Typography>
                          <ShieldCheck fill="#0DC07B" color="white" />
                        </Box>
                        <Typography sx={{ fontSize: '13px',  textDecorationLine: "underline", color: '#7E60BF', width: '125px' ,cursor:'pointer'}} 
                        //  onClick={handleOpenUpdateModal}
                        onClick={handleNavigateToMaid} 
                         >Update Professional</Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: "10px", }}>
                        <img src={`https://api.sevaki.in/${maidDetails?.subCategoryId?.imagePath}`} width="80px" height="80px" alt={maidDetails?.subCategoryId?.name} />
                        <Typography variant="h5">{maidDetails?.subCategoryId?.name}</Typography>
                      </Box>

                      <Box><Typography variant="h5" fontWeight="bold" color="primary">₹{maidDetails?.subCategoryId?.basePrice}</Typography></Box>
                    </Box>
                  </Box>
                
                </Box>
                <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderTop: "none", borderRadius: "0px 0px 10px 10px", }}>
                  <Box sx={{display:'flex',justifyContent:'space-between'}}>


                  <Typography variant="h5" >Payment Summary</Typography>
                  <Typography sx={{ fontSize: '15px', color: ' #7E60BF', cursor: 'pointer', textDecorationLine: "underline",mt:'4px' }}>
                    View Breakup
                  </Typography>
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Service Price</Typography>
                    <Typography variant="body2">₹399.00</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <Typography variant="body1" textAlign="center" fontSize="13px">Add-on</Typography>
                      <Info size={13} />
                    </Box>
                    <Typography variant="body2">₹0.00</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Fees & Taxes</Typography>
                    <Typography variant="body2">₹0.00</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Discount</Typography>
                    <Typography variant="body2">₹0.00</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", color: "#E4E4E4", mt: 2, mb: 2, }}>
                    <Box sx={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#E4E4E4", }} />
                    <Divider sx={{ flexGrow: 1, borderColor: "#E4E4E4", borderWidth: 1, borderStyle: "solid", }} />
                    <Box sx={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#E4E4E4", }} />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h5" fontSize="13px" sx={{ fontWeight: "bold", color: "primary.main" }}>Total Price</Typography>
                    <Typography variant="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>₹599.00</Typography>
                  </Box>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px", mt: 2 }}>
                      <Typography variant="h5">Booking Slots</Typography>


                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="body1" fontSize="13px">Cuisine</Typography>
                        <Typography variant="body2">{dynamicValues.cuisine || "South Indian"}</Typography> {/* Dynamically display the cuisine */}
                      </Box>

                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="body1" fontSize="13px">Meal Type</Typography>
                        <Typography variant="body2">{dynamicValues.mealType || "Veg & Non-Veg"}</Typography> {/* Dynamically display meal type */}
                      </Box>

                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="body1" fontSize="13px">Slot Type</Typography>
                        <Typography variant="body2">{dynamicValues.slotType || "Daily"}</Typography> {/* Dynamically display slot type */}
                      </Box>

                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="body1" fontSize="13px">Morning Timing</Typography>
                        <Typography variant="body2">{dynamicValues.morningTiming || "8:30 AM"}</Typography> {/* Dynamically display morning timing */}
                      </Box>

                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="body1" fontSize="13px">Evening Timing</Typography>
                        <Typography variant="body2">{dynamicValues.eveningTiming || "6:30 PM"}</Typography> {/* Dynamically display evening timing */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Box sx={{ border: "1px solid #E4E4E4", p: 2, mt: 2, borderRadius: "10px", }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", }}>
                      <BadgePercent size={20} fill="#0DC07B" color="#FFFFFF" />
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>Coupons & Offers</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "20px", }}>
                      <Typography variant="body2" color="primary">4 offers available</Typography>
                      <ChevronRight size={20} />
                    </Box>
                  </Box>
                </Box>

              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox size="small" checked={checkboxChecked} onChange={handleCheckboxChange} />

                <Typography variant="body2">I accept all Terms & Conditions and Privacy policy.</Typography>
              </Box>
              <Box sx={{
                display: "flex", justifyContent: "center", alignItems: "center", padding: "10px",
                position: isSmallScreen ? 'fixed' : isMediumScreen ? '100%' : 'auto',
                bottom: isSmallScreen ? '10px' : isMediumScreen ? '10px' : 'auto',
                left: isSmallScreen ? '105px' : isMediumScreen ? 'auto' : 'auto',
                // mt: isSmallScreen ? 15 : isMediumScreen ? 'auto' : 'auto',
              }} >

                <Button variant="contained"
                  sx={{

                    padding: "8px 25px", borderRadius: "30px",
                    backgroundColor: checkboxChecked ? "#7E62CC" : "transparent",
                    border: checkboxChecked ? "none" : "1px solid #E4E4E4",
                    color: checkboxChecked ? "#FFFFFF" : "black",
                    cursor: checkboxChecked ? "pointer" : "not-allowed",
                    width: isSmallScreen ? '160px' : isMediumScreen ? 'auto' : 'auto'
                  }} onClick={createBooking} disabled={!checkboxChecked}
                >Proceed to Pay
                </Button>
              </Box>
            </Grid>

          </Grid>
        </Box>

        <AddressPopup open={addressOpen} onClose={handleCloseAddressPopUp} address={selectedAddress} />

      </>
      {openSuccess && (<Bookingsuccessfull open={openSuccess} onClose={handleCloseSuccess} />)}
      <Reschedulepopup open={openReschedulePopup} onClose={handleCloseReschedulePopup} />
      {/* {openUpdateModal && (
                <UpdateMiad open={openUpdateModal} onClose={handleCloseUpdateModal} />
            )} */}

    </Container >
  );
};

export default Checkoutpage;
