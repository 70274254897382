import React, { useContext } from 'react';
import { Box } from "@mui/material";
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home'; // Ensure this path is correct
import Membership from '../pages/Membership';
import Total from '../pages/Maid/Total';
import Navbar from '../components/Navbar';
import Checkoutpage from '../pages/Checkoutpage';
import MyBookings from '../pages/Profile/Booking/MyBookings';
import BookingDetails from '../pages/Profile/Booking/BookingDetails';
import Faq from '../pages/Faq';
import ProfileSummary from '../pages/Profile/UserProfile/ProfileSummary';
import ProfileDetails from '../pages/Profile/UserProfile/ProfileDetails';
import Notifications from '../pages/Profile/UserProfile/Notifications';
import MobileNumber from '../pages/Profile/UserProfile/MobileNumber';
import AboutSevaki from '../pages/AboutSevaki';
import ContactUs from '../pages/ContactUs';
import Chat from '../pages/Chat';
import MiniCart from '../pages/MiniCart.js';
import MyAddress from '../pages/Profile/UserProfile/MyAddress';
import ProtectedRoute from './ProtectedRoute';
import { Context } from '../context/Context.js';
import Error from '../common/Error.js';
import FavouriteMaids from '../pages/Profile/UserProfile/FavouriteMaids.js';

const PageRoute = () => {
  const { openErrorBar } = useContext(Context)
  return (
    <Box>
      {openErrorBar && <Error />}
      <Routes>
        <Route path='/' element={<Home />} /> {/* Home route */}
        <Route path='/membership' element={<Membership />} />
        <Route path='/maid' element={<Total />} />
        <Route path='/checkout' element={<Checkoutpage />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/minicart' element={<MiniCart />} />
        <Route path='/mybookings' element={<ProtectedRoute><MyBookings /></ProtectedRoute>} />
        <Route path='/bookingdetails/:id' element={<ProtectedRoute><BookingDetails /></ProtectedRoute>} />
        <Route path='/profile' element={<ProtectedRoute><ProfileSummary /></ProtectedRoute>} >
          <Route path="details" element={<ProfileDetails />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="mobile-change" element={<MobileNumber />} />
          <Route path="myaddress" element={<MyAddress />} />
        </Route>
        <Route path="/chat" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
        <Route path="/favouritemaid" element={<ProtectedRoute><FavouriteMaids/></ProtectedRoute>} />
        <Route path="/aboutus" element={<AboutSevaki />} />
      </Routes>
    </Box>
  );
};
export default PageRoute;
