import React, { useContext, useState } from 'react';
import {
  Box,
  Modal,
  Backdrop,
  Fade,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  FormControlLabel,
  Button,
} from '@mui/material';
import { MapPin, MoreVertical, LocateFixed, X, Edit2, Trash } from 'lucide-react'; // Import icons for edit and delete
import AddressPopup from "./AddressPopup";
import { Context } from '../context/Context';
import axios from 'axios';
import apiUrl from '../config';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBwWB3Wca2DvrVbZLFjxwJ9HGyTd43hBFQ';

const LocationPopup = ({ open, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addressPopupOpen, setAddressPopupOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [currentAddressId, setCurrentAddressId] = useState(null);
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const { addressTypes, userAddress, states, setUserCurrentLocation, userToken, getUserDetailsByToken } = useContext(Context);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.trim() === '') {
      setSuggestions([]);
      return;
    }

    const results = await getAutocompleteSuggestions(value);
    setSuggestions(results);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion.description);
    setSelectedLocation(suggestion);
    setUserCurrentLocation(suggestion);
    setSuggestions([]);
    onClose();
  };

  const handleAddAddressClick = (address) => {
    setAddressPopupOpen(true);
    setSelectedLocation(address);
  };


  const handleUseCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`;
            const response = await fetch(geocodeUrl);
            const data = await response.json();

            if (data.results && data.results.length > 0) {
              const currentLocation = {
                description: data.results[0].formatted_address,
                place_id: 'current_location',
              };

              setSearchTerm(currentLocation.description);
              setUserCurrentLocation(currentLocation);
              setSuggestions([]);
              onClose();
            } else {
              console.log('Unable to retrieve your location. Please try again.');
            }
          } catch (error) {
            console.log('Error fetching location:', error);
          }
        },
        (error) => {
          console.log('Geolocation error:', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by your browser.');
    }
  };

  const getAutocompleteSuggestions = async (searchTerm) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURIComponent(searchTerm)}&key=${GOOGLE_MAPS_API_KEY}`;
    const response = await fetch(geocodeUrl);
    const data = await response.json();
    return data.predictions.slice(0, 5);
  };

  const clearInput = () => {
    setSearchTerm('');
    setSuggestions([]);
    setSelectedLocation(null);
  };

  const handleAddressPopupClose = () => {
    setAddressPopupOpen(false);
  };

  const displayStateName = (state) => {
    const stateObj = states.find((val) => val.id === state);
    return stateObj?.stateName;
  };

  const handleDelete = async (address) => {
    try {
      const response = await axios.delete(apiUrl + `users/deleteuseraddressbytoken?id=${address.id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if (response.data.status === true) {
        getUserDetailsByToken()
      }
    } catch (error) {
      console.error('Delete address:', error);
    }
  };

  const handleMenuClick = (event, addressId) => {
    setMenuAnchor(event.currentTarget);
    setCurrentAddressId(addressId);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleAddressSelect = (addressId) => {
    setSelectedAddressId(addressId);
    const selectedAddress = userAddress.find((addr) => addr.id === addressId);
    setUserCurrentLocation(selectedAddress);
  };

  return (
    <>

      <Modal open={open} onClose={onClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: '10px',
              outline: 'none',
            }}
          >
            <Box>
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <MapPin size={17} style={{ position: 'absolute', left: 10, color: '#000' }} />
                <input type="text" value={searchTerm}
                  onChange={handleInputChange}
                  placeholder="Enter your location"
                  style={{
                    width: '100%',
                    padding: '10px 40px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    fontSize: '16px',
                    outline: 'none',
                  }}
                />
                {searchTerm && <X onClick={clearInput} style={{ position: 'absolute', right: 10, cursor: 'pointer', color: '#888' }} size={20} />}
              </div>
              <Typography
                sx={{ display: 'flex', alignItems: 'center', color: '#7E60BF', cursor: 'pointer', mt: 2 }}
                onClick={handleUseCurrentLocation}
              >
                <LocateFixed size={18} style={{ paddingRight: '8px' }} /> Use current location
              </Typography>
              {suggestions.length > 0 && (
                <List sx={{ mt: 2, borderTop: '4px solid #EBEBEB', maxHeight: '200px', overflowY: 'scroll' }}>
                  {suggestions.map((suggestion, index) => (
                    <Box key={suggestion.place_id}>
                      <ListItem onClick={() => handleSuggestionClick(suggestion)} sx={{ cursor: 'pointer' }}>
                        <MapPin style={{ marginRight: '8px', flexShrink: 0 }} />
                        <ListItemText primary={suggestion.description} />
                      </ListItem>
                      {index < suggestions.length - 1 && <Divider />}
                    </Box>
                  ))}
                </List>
              )}
              <Divider sx={{ mt: 2 }} />
              <Box mt={2}>
                <Typography variant="h5">Saved Addresses</Typography>
                {userAddress?.map((address, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 1,
                    }}
                  >
                    <FormControlLabel control={
                        <Radio
                          checked={selectedAddressId === address.id}
                          onChange={() => handleAddressSelect(address.id)}
                          value={address.id}
                          sx={{ marginTop:'-27px',
                            color: '#7E60BF',
                            '&.Mui-checked': {
                              color: '#7E60BF',
                            },
                          }}
                        />
                      }
                      label={
                        <Box sx={{ cursor: 'pointer', flex: 1 }}>
                          <Typography variant="h6">
                            {addressTypes.find((type) => type.id === address.addressType_Id)?.name}
                          </Typography>
                          <Typography>
                            {`${address.address1}, ${address.address2}, ${address.city}, ${displayStateName(address.state)}, ${address.pincode}`}
                          </Typography>
                        </Box>
                      }
                      sx={{ flex: 1 }}
                    />
                    <IconButton onClick={(e) => handleMenuClick(e, address.id)} >
                      <MoreVertical />
                    </IconButton>
                    <Menu anchorEl={menuAnchor} open={menuAnchor && currentAddressId === address.id} onClose={handleMenuClose}>
                      <MenuItem onClick={() => handleAddAddressClick(address)}>
                        <Edit2 style={{ marginRight: '8px' }} /> Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(address)}>
                        <Trash style={{ marginRight: '8px' }} /> Delete
                      </MenuItem>
                    </Menu>
                  </Box>
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button variant="contained" onClick={() => handleAddAddressClick(null)}>
                    + Add Address
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <AddressPopup open={addressPopupOpen} onClose={handleAddressPopupClose} address={selectedLocation} />
    </>
  );
};
export default LocationPopup;

