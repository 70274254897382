import React, { useContext, useState } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import apiUrl from '../../config';
import { Context } from '../../context/Context';

function BookingCancellation({ onClose, bookingId }) {
    const { userToken } = useContext(Context);
    const [selectedReason, setSelectedReason] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const reasons = [
        'Booking address is incorrect',
        'Professional not assigned',
        'Placed the request by mistake',
        'Service no longer required',
        'Hired someone else outside Sevaki',
    ];


    const handleReasonChange = (value) => {
        setSelectedReason(value);
    };

    const handleCancelBooking = async () => {
        setIsSubmitting(true);
        try {
            const response = await axios.post(
                apiUrl + `booking/cancelbookingbyuser?bookingId=${bookingId}`,
                `${selectedReason}`,
                {
                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${userToken}`
                    },
                }
            );
            if (response.data.status === true) {
                onClose();
            }
        } catch (error) {
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1300,
            }}
        >
            <Box
                sx={{
                    width: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 24,
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: '#f0ebfb',
                        padding: '16px',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h5" sx={{ color: '#7E60BF', fontWeight: 'bold' }}>
                            Booking Cancellation
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="body2" sx={{ color: 'gray', marginTop: '8px' }}>
                        We won’t be charging you a cancellation fee.
                    </Typography>
                </Box>
                <Box sx={{ padding: '24px' }}>
                    {reasons.map((reason) => (
                        <Box
                            key={reason}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                marginBottom: '8px',
                            }}
                            onClick={() => handleReasonChange(reason)}
                        >
                            {selectedReason === reason ? (
                                <CheckCircle sx={{ color: '#7E60BF', fontSize: '20px', marginRight: '8px' }} />
                            ) : (
                                <RadioButtonUnchecked sx={{ color: 'black', fontSize: '20px', marginRight: '8px' }} />
                            )}
                            <Typography variant="body1">{reason}</Typography>
                        </Box>
                    ))}

                    <Box sx={{ textAlign: 'center', padding: '10px 0' }}>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: 'primary.main', borderRadius: '20px' }}
                            onClick={handleCancelBooking}
                            disabled={isSubmitting || !selectedReason?.trim()}
                        >
                            {isSubmitting ? 'Cancelling...' : 'Cancel Booking'}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default BookingCancellation;