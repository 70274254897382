import React, { useState } from 'react'
import { Box, Container } from "@mui/material";
import OtherServices from './OtherServices';
import Maid from './Maid';
import Advertisements from './Advertisements';
import useResponsive from '../../hooks/useResponsive';
import Navbar from '../../components/Navbar';
import BottomNavBar from '../../components/BottomNavbar';

const Total = () => {
    const [selectSubService, setSelectSubService] = useState()
    const { isSmallScreen, isMediumScreen } = useResponsive()
    const handleSelectSubService = (subService) => {
        setSelectSubService(subService)
    }
    return (
        <>
            <Navbar />
            <BottomNavBar />

            <Container>
                <Box sx={{ display: isSmallScreen ? "block" : "flex", justifyContent: 'space-between' }}>
                    <Box sx={{ width: isSmallScreen ? "100%" : "30%" }}><OtherServices handleSelectSubService={handleSelectSubService} /></Box>
                    <Box sx={{ padding: "10px", width: isSmallScreen ? "100%" : "50%" }}><Maid selectedSubService={selectSubService} /></Box>
                    <Box sx={{ width: "20%", display: isSmallScreen ? "none" : "block" }}><Advertisements /></Box>
                </Box>
            </Container>
        </>
    )
}

export default Total