import React from 'react';
import { Box, Typography, Container, Grid, IconButton, Link ,styled} from '@mui/material';
import { Instagram, Facebook, Twitter, Youtube } from 'lucide-react';
import useResponsive from '../hooks/useResponsive';
import logoImage from '../Assets/Images/sevakilogo2.svg'; 
const LogoImage = styled('img')({
    width: '80px', 
    height: '50px',
  });

const Footer = () => {
    const { isSmallScreen ,isMediumScreen} = useResponsive()

    return (
        <Box component="footer"  sx={{
                backgroundColor: '#f5f5f5',
                padding: '20px 0',
                marginTop: 10,
                borderTop: '1px solid #e0e0e0',
            }}
        >
            <Container maxWidth="lg">
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', padding: '5px' }}>
                <IconButton edge="start" color="inherit" aria-label="logo" sx={{ mr: 2 }} disableRipple
                disableFocusRipple>
          <LogoImage  src={logoImage}  alt="Sevaki Logo" sx={{ width: '160px', height: '50px', marginLeft: '85px' }} />
        </IconButton>
                </Box>
                <hr />
                <Grid container spacing={4} justifyContent="space-evenly" sx={{ marginTop: '0px' }} >


                    <Grid item xs={12} sm={4} md={2}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', fontSize: '20px' }}>  For Users </Typography>
                        <Box>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px' }}>
                                <Link href="#/aboutus" color="inherit" underline="none">About us</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px' }}>
                                <Link href="#/membership" color="inherit" underline="none">Membership</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px' }}>
                                <Link href="#" color="inherit" underline="none">Store</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px' }}>
                                <Link href="#" color="inherit" underline="none">Terms & Conditions</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: '13px' }}>
                                <Link href="#" color="inherit" underline="none">Privacy Policy</Link>
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', fontSize: '20px' }}> For Partners </Typography>
                        <Box sx={{ fontSize: '13px' }}>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px' }}>
                                <Link href="#" color="inherit" underline="none">Register us</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px' }}>
                                <Link href="#" color="inherit" underline="none">Training Center</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: '13px' }}>
                                <Link href="#" color="inherit" underline="none">Careers</Link>
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', fontSize: '20px' }}>  Social Media</Typography>
                        <Box display="flex" gap={2}>
                            <IconButton
                                href="https://www.instagram.com/sevaki.in?igsh=MTF3djdnZTdmM3lrdA==" target='_blank'
                                color="inherit" sx={{ '&:hover': { color: '#7E60BF' } }} >
                                <Instagram size={20} />
                            </IconButton>
                            <IconButton href="#" color="inherit"
                                sx={{ '&:hover': { color: '#7E60BF' } }}
                            >
                                <Facebook size={20} />
                            </IconButton>
                            <IconButton href="#" color="inherit"
                                sx={{ '&:hover': { color: '#7E60BF' } }}
                            >
                                <Twitter size={20} />
                            </IconButton>
                            <IconButton href="#" color="inherit" target='_blank'
                                sx={{ '&:hover': { color: '#7E60BF' } }}
                            >
                                <Youtube size={20} />
                            </IconButton>
                        </Box>
                    </Grid>

                    {/* Download the App */}

                    <Grid item xs={12} sm={4} md={3} textAlign={{ xs: 'center', md: 'left' ,marginBottom: isSmallScreen ? '15px' : '0px',}}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px' , fontSize:'20px'}}>
                            Download the app
                        </Typography>
                        <Box display="flex" gap={2} justifyContent={{ xs: 'center', md: 'left' }}>
                            <Link href="#" target="_blank">
                                <img
                                    src="https://e7.pngegg.com/pngimages/918/845/png-clipart-google-play-logo-google-play-app-store-android-google-play-text-logo.png"
                                    alt="Google Play" style={{ width: '120px' }}
                                />
                            </Link>
                            <Link href="#" target="_blank">
                                <img
                                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                    alt="App Store" style={{ width: '110px' }}
                                />
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
                <hr />

                <Box textAlign="center" mt={2}>
                    <Typography variant="body2" color="#000000">
                        © Copyright 2024 <a href="https://Abilioit.com" target='_blank' color="#1c2b5a">Abilioit.com</a>. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;